import { signInWithEmailAndPassword } from 'firebase/auth'
import { authInstance } from './index'

interface LoginCredentials {
  email: string
  password: string
}
interface AuthResponse {
  isDone: boolean
}
export const authenticateUser = async (
  credentials: LoginCredentials,
): Promise<AuthResponse> => {
  try {
    const userCredential = await signInWithEmailAndPassword(
      authInstance,
      credentials.email,
      credentials.password,
    )
    if (userCredential.user) {
      console.log(userCredential)
      return { isDone: true }
    }
    return { isDone: false }
  } catch (error: any) {
    return { isDone: false }
  }
}
