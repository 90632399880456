import React, { useEffect } from 'react'
import filledTrashCan from 'assets/home/filled-_trash.svg'
import ScrollReveal from 'scrollreveal'
import Reveal from 'molecules/reveal'

const stats = [
  'Around 1.3 billion tonnes of food is being wasted globally.',
  'One-third of all food in India goes to waste, as reported by FSSAI.',
  'It is the culprit behind 8-10% of global greenhouse gas emissions.',
  'Food worth Rs 92,000 crore goes down the drain every year.',
]

export default function Statistics() {
  useEffect(() => {
    ScrollReveal().reveal('.subtitle', { duration: 200 })
    ScrollReveal().reveal('.hashtag', { duration: 200, delay: 200 })
  }, [])

  return (
    <section className="blade-top-padding-lg blade-top-margin">
      <section className="blade-top-padding blade-bottom-padding-sm">
        <Reveal>
          <h3 className=" subtitle text-center px-3 font-extrabold ">
            Solving The Dumbest Problem
          </h3>
        </Reveal>
        <Reveal delay={900}>
          <h1 className=" hashtag text-green px-3 text-center font-extrabold mt-1 xl:mt-2">
            #FoodWaste
          </h1>
        </Reveal>
      </section>
      <section className="grid place-content-center blade-bottom-padding">
        <img
          src={filledTrashCan}
          alt="Filled trash can showing food wastage."
          className="w-11/12 mx-auto"
        />
      </section>
      <section className="mt-5 ">
        <section className="w-container-lg  mx-auto grid md:grid-cols-2 2xl:grid-cols-4  grid-cols-1  gap-6 md:gap-8 w-10/12 blade-bottom-padding-lg blade-bottom-margin-lg blade-top-padding md:pt-0">
          {stats.map((elem: string, index: number) => {
            const key = `${index + 1}`
            return (
              <Reveal
                key={key}
                delay={400 + 150 * index}
                className="self-stretch min-h-full"
              >
                <StatCard stat={elem} />
              </Reveal>
            )
          })}
        </section>
      </section>
    </section>
  )
}

function StatCard({ stat }: { stat: string }) {
  return (
    <article
      style={{ background: 'linear-gradient(to bottom, #FFFFFF, #FFF6EE)' }}
      className="mx-auto  h-full max-w-md orange-outline-border md:p-8 px-7 py-12 xl:p-12 text-center"
    >
      <h6>{stat}</h6>
    </article>
  )
}
