import React from 'react'

export default function DrumRoll() {
  return (
    <section className="w-container ">
      <h2 className="text-center px-3 md:block hidden md:w-11/12  mx-auto ">
        <span className="text-orange font-bold">Congratulations!</span> By
        choosing us, you&apos;re <br className="2xl:block hidden" />
        not just enjoying lip-smacking food, you{' '}
        <br className="2xl:block hidden" />
        are also saving the planet!
      </h2>
      <h3 className="text-center px-3 md:hidden block">
        <span className="text-orange font-bold">Congratulations!</span> By
        choosing us, you&apos;re not just enjoying lip-smacking food, you are
        also saving the planet!
      </h3>
    </section>
  )
}
