import React, { useLayoutEffect } from 'react'
import JoinUs from 'organisms/joinUs'
import Banner from './01_banner'
import Statistics from './02_statistics'
import Globe from './03_globe'
import HowTo from './04_howto'
import DrumRoll from './05_drumroll'
import MobileApp from './06_mobile_app'
import WinWin from './07_win_win'
import SuperPower from './08_superpower'
import './index.css'

export default function HomePage() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <main>
      <Banner />
      <Statistics />
      <Globe />
      <HowTo />
      <DrumRoll />
      <MobileApp />
      <WinWin />
      <div
        style={{
          background: 'linear-gradient(180deg, #CBDBE5 0%, #F0EAEC 100%)',
        }}
      >
        <SuperPower />
        <JoinUs />
      </div>
    </main>
  )
}
