/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useReducer, useRef, useEffect, useState } from 'react'
import logo_with_text from 'assets/globals/logo_black_text.svg'

import { Link } from 'react-router-dom'
import { MasterAnchor, TextNavAnchor, TextAnchor } from 'atoms/links'

import { gsap, Power1 } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

export default function Header() {
  const [isMobileNavActive, toggleNav] = useReducer((s) => !s, false)

  const headerWrapper = useRef<HTMLElement>(null)

  useEffect(() => {
    if (isMobileNavActive) document.documentElement.style.overflow = 'hidden'
    else document.documentElement.style.overflow = 'auto'
  }, [isMobileNavActive])

  useEffect(() => {
    const showAnim = gsap
      .from(headerWrapper.current, {
        opacity: 0,
        translateY: -150,
        paused: true,
        duration: 0.4,
        ease: Power1.easeInOut,
      })
      .progress(1)

    ScrollTrigger.create({
      start: 'top top',
      end: 99999,
      onUpdate: (self) => {
        if (self.direction === -1) showAnim.play()
        else showAnim.reverse()
      },
    })
  }, [])

  return (
    <>
      <header
        ref={headerWrapper}
        className="fixed lg:py-10 flex justify-center  top-0 left-0 right-0 bottom-auto z-50"
      >
        <nav className="lg:rounded-full  w-full bg-white max-w-6xl mx-auto md:mx-0 lg:mx-3 lg:w-11/12 lg:border-1 border-black border-opacity-20 py-3 flex flex-row px-3  lg:px-8 ">
          <div className="grid place-content-center">
            <Link
              to="/"
              onClick={() => {
                if (isMobileNavActive) toggleNav()
              }}
            >
              <img
                src={logo_with_text}
                className="h-12 md:h-14 lg:h-20  py-1 lg:py-4 "
                alt="Good Deed Logo."
                loading="eager"
              />
            </Link>
          </div>
          <ul className="flex-1 hidden md:flex   lg:flex justify-end items-center gap-10 pr-2 px-5  ">
            <li className="flex-1 flex justify-evenly items-center gap-2 px-6 2xl:px-10 ">
              <TextNavAnchor
                text="For Business"
                href="/business"
                color="dark"
                size="large"
              />
              <TextNavAnchor
                text="About Us"
                href="/about"
                size="large"
                color="dark"
              />
              <TextNavAnchor
                size="large"
                text="Contact Us"
                href="/contact"
                color="dark"
              />
            </li>
            <li className="flex-0">
              <MasterAnchor text="Join Now" size="base" href="/contact" />
            </li>
          </ul>
          <div className="lg:hidden ml-auto  grid place-content-center p-3 rounded-full ">
            <button
              type="button"
              onClick={toggleNav}
              className="rounded-full focus-visible:bg-lightGray  hover:bg-lightGray p-2"
            >
              {isMobileNavActive ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                  />
                </svg>
              )}
            </button>
          </div>
        </nav>
      </header>

      <div
        className={` ${
          isMobileNavActive
            ? 'translate-x-0 border-b-1 border-solid border-black border-opacity-30 '
            : 'translate-x-full'
        }   fixed top-0 left-0   pb-6 pt-28 right-0 bottom-0 overflow-hidden tranistion-all duration-700 ease-in-out bg-white px-3 flex  flex-col z-40`}
      >
        <ul className="flex-1   flex flex-col pr-2 gap-5 ">
          <li
            className="  grid  "
            onClick={toggleNav}
            onKeyDown={toggleNav} // Include keydown event for accessibility
          >
            <TextAnchor
              text="For Business"
              size="xxl"
              href="/business"
              color="dark"
            />
          </li>
          <li className="  grid  " onClick={toggleNav} onKeyDown={toggleNav}>
            <TextAnchor size="xxl" text="About Us" href="/about" color="dark" />
          </li>
          <li className="  grid  " onClick={toggleNav} onKeyDown={toggleNav}>
            <TextAnchor
              size="xxl"
              text="Contact"
              href="/contact"
              color="dark"
            />
          </li>
        </ul>

        <ul className="list-none">
          <li onClick={toggleNav} onKeyDown={toggleNav}>
            <MasterAnchor text="Join Now" size="base" href="/contact" />
          </li>
        </ul>
      </div>
    </>
  )
}
