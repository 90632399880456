import React from 'react'
import mobile from 'assets/business/mobile_mock.webp'
import Slide from 'molecules/slide'

const items = [
  'Eateries upload fresh surplus dishes on the Good Deed app.',
  'Good Deed conducts thorough quality checks.',
  'Foodies order through the app.',
  'You save sunk costs and save the planet.',
]
export default function HowItWorks() {
  return (
    <section>
      <div className="blade-top-padding-lg blade-bottom-padding-lg">
        <Slide>
          <h2 className="font-extrabold text-orange text-center px-3">
            How it works?
          </h2>
        </Slide>
      </div>
      <div className="  flex  flex-col xl:flex-row justify-around  gap-4 w-container  pt-5 lg:pt-6 ">
        <div className="  flex-1 flex items-end justify-center ">
          <Slide
            distance="100%"
            delay={100}
            className=" max-h-[800px] w-11/2 max-w-[450px] lg:max-w-[500px]"
          >
            <img
              src={mobile}
              alt="Mobile App Mockup"
              loading="eager"
              className=" h-full object-center object-contain w-auto "
            />
          </Slide>
        </div>

        <div className=" max-w-3xl flex-1 mx-auto  ">
          <ul className="list-none grid  gap-6 2xl:gap-10 h-full    py-6 md:py-8 lg:py-12 xl:py-16 mr-auto  sm:w-11/12 2xl:w-9/12  ">
            {items.map((elem, index) => {
              const key = index
              return <Item index={key} title={elem} key={key} />
            })}
          </ul>
        </div>
      </div>
    </section>
  )
}

function Item({ title, index }: { title: string; index: number }) {
  return (
    <Slide delay={200 + 200 * index}>
      <li
        className="flex  flex-col sm:flex-row items-start sm:items-center gap-5  py-4  px-6 border-2 border-solid border-orange border-opacity-10  rounded-xl"
        style={{
          background: 'linear-gradient(171deg, #FFEBDD 0%, #FFFDFB 100%)',
          boxShadow: '7px 9px 20px 0px #FFE9D9',
        }}
      >
        <div
          className=" w-16 sm:basis-20 rounded-xl py-3 grow-0 shrink border-1 border-solid border-orange grid place-content-center"
          style={{
            background: ' linear-gradient(180deg, #FFF 0%, #FFF6EE 100%)',
          }}
        >
          <h5 className="font-extrabold text-orange">{index + 1}</h5>
        </div>
        <div className="flex-1  pr-10 lg:pr-16  xl:pr-10  2xl:pr-12 pb-4 sm:pb-0">
          <span className=" text-xl md:block hidden leading-tight">
            {title}
          </span>
          <span className="block md:hidden">{title}</span>
        </div>
      </li>
    </Slide>
  )
}
