import React from 'react'
import { MasterBtn } from 'atoms/buttons'
import { useNavigate } from 'react-router-dom'

export default function JoinUs() {
  const navigate = useNavigate()
  const navToContact = () => {
    navigate('/contact')
  }
  return (
    <section className="blade-top-padding-lg blade-bottom-padding">
      <div className="blade-top-padding blade-bottom-padding">
        <h1 className="md:block hidden font-extrabold px-3 text-center ">
          Ready to do a Good Deed?
        </h1>
        <h2 className=" md:hidden block  font-extrabold px-3 text-center">
          Ready to do a Good Deed?
        </h2>
        <div className="grid place-content-center px-3   py-8 lg:py-10 blade-bottom-padding-lg blade-bottom-margin ">
          <MasterBtn
            text="Join The Waitlist"
            size="xl"
            type="button"
            onClick={navToContact}
          />
        </div>
      </div>
    </section>
  )
}
