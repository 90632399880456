import React from 'react'

export default function Banner() {
  return (
    <section className="blade-top-padding-lg ">
      <div className="blade-top-padding-lg md:pt-0">
        <div className="blade-top-padding-lg blade-top-margin-lg ">
          <h2 className="font-bold text-center px-3  md:block hidden">
            It&apos;s never too late to start!
          </h2>
          <h3 className="font-bold text-center px-3  md:hidden block">
            It&apos;s never too late to start!
          </h3>
          <h1 className="text-center px-3 font-bold  text-green  md:block hidden  ">
            Let&apos;s connect today.
          </h1>
          <h2 className="text-center px-3 font-bold  text-green md:hidden block ">
            Let&apos;s connect today.
          </h2>
        </div>
      </div>
    </section>
  )
}
