import React from 'react'
import logo_with_text from 'assets/globals/logo_with_text.webp'
import { TextAnchor } from 'atoms/links'
import linkedInIcon from 'assets/footer/linkedIn.svg'
import twitterIcon from 'assets/footer/twitter.svg'
import instagramIcon from 'assets/footer/instagram.svg'
import instMesh from 'assets/footer/insta-mesh.svg'
import orangeMesh from 'assets/footer/orange-mesh.svg'
import twitterMesh from 'assets/footer/twitter-mesh.svg'
import angularCircle from 'assets/footer/anguler-circle.svg'

export default function Footer() {
  return (
    <footer className="bg-paleBlue text-white  py-8 md:pb-12 pt-20 ">
      <section>
        <img
          src={logo_with_text}
          alt="Good Deed's Logo"
          className="w-auto object-contain  h-12  md:h-16 xl:h-20 mx-auto"
          loading="lazy"
        />
      </section>
      <section className="text-center px-3  font-bold pt-6  md:pt-8  lg:pt-12">
        <h3>Let’s save the Earth together</h3>
        <h5 className="text-yellow font-semibold mt-5 md:pt-8 md:block hidden">
          Follow us on
        </h5>
      </section>
      <section className="grid    max-w-lg lg:max-w-2xl grid-cols-3 mx-auto gap-y-10 my-12  md:py-8 px-4  ">
        <SocialIcon
          bgImg={twitterMesh}
          text="LinkedIn"
          img={linkedInIcon}
          href={process.env.REACT_APP_LINKEDIN ?? 'https://gooddeed.in'}
        />
        <SocialIcon
          bgImg={orangeMesh}
          text="Instagram"
          img={instagramIcon}
          href={process.env.REACT_APP_INSTA ?? 'https://gooddeed.in'}
        />
        <SocialIcon
          bgImg={instMesh}
          text="Twitter"
          img={twitterIcon}
          href={process.env.REACT_APP_TWITTER ?? 'https://gooddeed.in'}
        />
      </section>
      <section className="flex lg:flex-row flex-col border-t-1 md:border-b-1 lg:divide-x-1 divide-solid divide-white px-3 md:px-0">
        <div className="flex-1  hidden lg:grid place-content-center  md:px-5 lg:px-10 text-center  py-4">
          <span className="text-white text-sm text-opacity-80 font-light">
            Copyright@2023. Good Deed.
          </span>
        </div>
        <div className="flex md:flex-row flex-col items-start md:items-center md:mx-auto w-full  justify-evenly   md:px-5 lg:px-10 gap-3  flex-1  pb-4  pt-10  md:pt-4 font-light ">
          <TextAnchor color="light" href="/" text="Home" />
          <TextAnchor color="light" href="/about" text="About" />
          <TextAnchor color="light" href="/business" text=" For Business" />
          <TextAnchor color="light" href="/contact" text="Contact" />
        </div>
        <div className="flex-1  items-start md:items-center md:px-5 lg:px-10  flex md:flex-row flex-col gap-3 justify-evenly  md:mx-auto w-full py-3 font-light">
          <TextAnchor
            size="small"
            color="light"
            href="/terms-and-conditions"
            text="Terms and Conditions"
          />
          <TextAnchor
            size="small"
            color="light"
            href="/privacy-policy"
            text="Privacy Policy"
          />
        </div>
        <div className="md:text-center lg:hidden pt-4  md:pt-10 md:pb-10">
          <span className="text-white  text-sm text-opacity-60 font-light ">
            Copyright@2023. Good Deed.
          </span>
        </div>
      </section>
    </footer>
  )
}

type SocialIconProps = {
  href: string
  text: string
  img: string
  bgImg: string
  desc?: string
}
function SocialIcon({ href, text, img, desc, bgImg }: SocialIconProps) {
  return (
    <div className="grid place-content-center w-24 md:w-10/12 mx-auto  group  ">
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        className="bg-white rouned-xl md:max-w-none p-2 rounded-xl"
      >
        <div className="relative grid place-content-center">
          <div className="h-full w-full absolute left-0 top-0 bottom-0 right-0 grid place-content-center  z-10">
            <img src={img} alt={desc} className="h-8" />
          </div>
          <div className="h-full w-full absolute left-0 top-0 bottom-0 right-0 grid place-content-center  group-hover:animate-spin-slow transition-all duration-300 ease-in-out">
            <img
              src={angularCircle}
              alt={desc}
              className="w-10/12  object-center mx-auto object-contain "
            />
          </div>
          <img src={bgImg} alt={desc} />
        </div>
        <div className="px-2  pt-1 md:pt-3 pb-1 overflow-hidden text-black text-center text-2xl font-semibold">
          <span className="text-[12px] md:text-lg ">{text ?? ''}</span>
        </div>
      </a>
    </div>
  )
}
