import React from 'react'
import banner from 'assets/error404/banner.svg'

export default function Error404() {
  return (
    <section
      style={{
        background: 'linear-gradient(180deg, #CBDBE5 0%, #F0EAEC 100%)',
      }}
      className="blade-bottom-padding-lg blade-top-padding-lg"
    >
      <section className="blade-top-padding-lg blade-bottom-padding-lg">
        <section className="blade-top-padding blade-bottom-padding ">
          <div className=" grid place-content-center">
            <img
              src={banner}
              alt="Graphic showing that requested resource is not found."
              loading="eager"
              className="  mx-auto w-10/12 max-w-4xl lg:w-full h-full object-contain object-center"
            />
          </div>
        </section>
      </section>
    </section>
  )
}
