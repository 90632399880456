import React, { useRef, MouseEvent } from 'react'
import banner from 'assets/business/banner.webp'
import { gsap } from 'gsap'
import Slide from 'molecules/slide'
import Reveal from 'molecules/reveal'

export default function Banner() {
  const imgWrapper = useRef<HTMLImageElement>(null)
  const handleMouse = (event: MouseEvent) => {
    if (imgWrapper.current) {
      const divRect = imgWrapper.current.getBoundingClientRect()
      const centerX = divRect.left + divRect.width / 2
      const centerY = divRect.top + divRect.height / 2
      const translateX = (event.clientX - centerX) * 0.05
      const translateY = (event.clientY - centerY) * 0.1
      gsap.to(imgWrapper.current, {
        duration: 0.5,
        x: translateX,
        y: translateY,
        overwrite: true,
      })
    }
  }

  const handleMouseOut = () => {
    gsap.to(imgWrapper.current, {
      duration: 0.5,
      x: 0,
      y: 0,
      overwrite: true,
    })
  }

  return (
    <section>
      <div className="mt-10 md:mt-0">
        <div className="blade-top-padding-lg blade-bottom-padding-lg mb-4 ">
          <h2 className=" md:block hidden font-bold text-center  px-5  sm:w-10/12 lg:w-full mx-auto blade-top-padding-lg blade-top-margin-lg leading-relaxed">
            <Slide className="inline-block">
              <span>
                Save sunk costs <br className="md:block hidden" />
              </span>
            </Slide>{' '}
            <br className="md:block hidden" />
            <Slide className="inline-block" delay={500}>
              <span className="text-green"> Save the environment</span>
            </Slide>
          </h2>
          <h3 className=" md:hidden block font-bold text-center  px-5  sm:w-10/12 lg:w-full mx-auto blade-top-padding-lg blade-top-margin-lg leading-relaxed">
            Save sunk costs <br />{' '}
            <span className="text-green"> Save the environment</span>
          </h3>
        </div>
      </div>
      <section
        onMouseMove={(e: MouseEvent) => {
          handleMouse(e)
        }}
        onMouseOut={() => {
          handleMouseOut()
        }}
        onBlur={handleMouseOut}
        className="   h-72 md:h-96 lg:h-[30rem] xl:h-[36rem] 2xl:h-[42rem] md:w-container-sm  mx-auto"
      >
        <Reveal>
          <div className="img-wrapper w-11/12 mx-auto  h-full rounded-3xl md:rounded-full overflow-hidden  ">
            <img
              ref={imgWrapper}
              src={banner}
              alt="Show the imporrtance of food."
              className="w-full h-full object-cover object-center  scale-110"
            />
          </div>
        </Reveal>
      </section>
    </section>
  )
}
