import React, { ReactNode } from 'react'
import ReactMarkdown from 'react-markdown'

type Props = { children: ReactNode }
type Anchor = { children: ReactNode; href: string }

function Heading1({ children }: Props) {
  return <h1 className="font-extrabold text-green">{children}</h1>
}

function Heading2({ children }: Props) {
  return <h2>{children}</h2>
}

function Heading3({ children }: Props) {
  return <h3>{children}</h3>
}

function Heading4({ children }: Props) {
  return (
    <h4 className="pt-6 md:pt-8 lg:pt-12  pb-3 md:pb-5  font-bold  text-green">
      {children}
    </h4>
  )
}

function Heading5({ children }: Props) {
  return <h5 className="font-bold text-darkGreen pb-1 pt-3  ">{children}</h5>
}
function Heading6({ children }: Props) {
  return <h6 className="font-bold pb-1 pt-4  ">{children}</h6>
}

function ListItem({ children }: Props) {
  return <li className="text-base md:text-lg pb-2 ">{children}</li>
}
function OrderedList({ children }: Props) {
  return (
    <ol className="leading-relaxed pb-3  text-base  md:text-lg  pl-6 list-decimal">
      {children}
    </ol>
  )
}

function UnorderedList({ children }: Props) {
  return (
    <ul className="leading-relaxed pb-3  text-base  md:text-lg  pl-6 list-disc">
      {children}
    </ul>
  )
}

function Para({ children }: Props) {
  return (
    <p className="leading-relaxed pb-3  text-base  md:text-lg ">{children}</p>
  )
}

const markdownConfig = {
  h1: Heading1,
  h2: Heading2,
  h3: Heading3,
  h4: Heading4,
  h5: Heading5,
  h6: Heading6,
  p: Para,
  a: Anchor,
  li: ListItem,
  ul: UnorderedList,
  ol: OrderedList,
}

function Anchor(props: React.HTMLAttributes<HTMLAnchorElement>) {
  const { children } = props
  return (
    <a
      {...props}
      target="_blank"
      className="underline font-bold text-green decoration-from-font underline-offset-2 lowercase "
    >
      {children}
    </a>
  )
}
export { markdownConfig }

export default function RenderMarkdown({ content }: { content: string }) {
  return <ReactMarkdown components={markdownConfig}>{content}</ReactMarkdown>
}
