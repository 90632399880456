import React, { useEffect, useState, ChangeEvent } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { authenticateUser } from '../../api/auth'
import { fetchFormSubmissions, FormFields } from '../../api/api'

export default function ListFormSubmissions() {
  const [isLoading, setLoading] = useState(true)
  const [data, setData] = useState<Array<FormFields>>([])

  const location = useLocation()
  const navigate = useNavigate()

  const searchParams = new URLSearchParams(location.search)
  const email = searchParams.get('email')
  const password = searchParams.get('password')

  const renderFormData = async () => {
    const resp = await fetchFormSubmissions()
    setData(resp)
  }
  useEffect(() => {
    if (email && password) {
      authenticateUser({ email, password }).then((isAuthenticated) => {
        if (isAuthenticated.isDone) {
          setLoading(false)
          renderFormData()
        } else navigate('/')
      })
    } else navigate('/')
  }, [location, navigate])

  if (isLoading) return <LoadingIndicator title="Loading..." />
  if (!data.length) return <LoadingIndicator title="Fetching Data..." />
  return <ListEntries data={data} />
}

function LoadingIndicator({ title }: { title: string }) {
  return (
    <div className="min-h-[600px] gap-3 flex items-center justify-center  flex-col text-center w-container">
      <h1 className="font-extrabold  text-green w-11/12 ">{title}</h1>
      <h3 className="text-black font-bold">Please wait..</h3>
    </div>
  )
}

function ListEntries({ data }: { data: Array<FormFields> }) {
  const [selectedDate, setSelectedDate] = useState<string>('')
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const dateValue: string = event.target.value
    setSelectedDate(dateValue)
  }
  return (
    <section className="blade-bottom-padding blade-top-padding">
      <div className="flex flex-col">
        <div className="min-h-[200px] blade-top-padding-lg blade-top-margin-lg lg:mb-10  gap-3  flex  items-center justify-center  flex-col text-center w-container">
          <h3 className="font-extrabold text-green w-11/12 ">
            Form Submissions
          </h3>
        </div>
        <div className="blade-bottom-margin blade-bottom-padding">
          <div>
            <div>
              <table className="max-w-screen-lg mx-auto md:w-11/12  border-1 border-solid border-lightGray">
                <thead>
                  <tr className="border-b-2 border-solid border-opacity-50 border-black">
                    <th className="py-3 px-4 text-left">Purpose</th>
                    <th className="py-3 px-4 text-left">Name</th>
                    <th className="py-3 px-4 text-left">Contact</th>
                    <th className="py-3 px-4 text-left">Message</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((elem, index) => {
                    const {
                      name = '',
                      email = '',
                      phone = '',
                      message = '',
                      purpose = '',
                    } = elem
                    const key = `${index}`

                    return (
                      <tr
                        key={key}
                        className="border-b-1 border-solid border-lightGray"
                      >
                        <td className="py-3 px-4 text-sm ">{purpose}</td>
                        <td className="py-3 px-4 text-sm ">{name} </td>
                        <td className="py-3 px-4 text-sm ">
                          <td className="grid">
                            <td>{email}</td>
                            <td>{phone}</td>
                          </td>
                        </td>
                        <td className="py-3 px-4 text-sm ">
                          <td>{message}</td>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
