import React, { useEffect } from 'react'
import one from 'assets/business/1.webp'
import two from 'assets/business/2.webp'
import three from 'assets/business/3.webp'
import Slide from 'molecules/slide'
import Reveal from 'molecules/reveal'

export default function CardGrid() {
  return (
    <section className="pt-10 md:pt-0">
      <div className=" blade-top-padding-lg blade-bottom-padding">
        <h2 className="text-center px-3 leading-relaxed font-medium  md:block hidden">
          <Slide>
            <span>Let’s create a world where </span>
          </Slide>
          <Slide delay={500}>
            <span className="font-bold text-orange  inline sm:block">
              {' '}
              good food never goes to waste!
            </span>
          </Slide>
        </h2>
        <h3 className="text-center px-3 leading-relaxed font-medium md:hidden block">
          Let’s create a world where{' '}
          <span className="font-bold text-orange  inline sm:block">
            {' '}
            good food never goes to waste!
          </span>
        </h3>
      </div>
      <div className=" w-container-lg 2xl:w-container  blade-bottom-padding-lg blade-bottom-margin-lg   py-4 md:pt-5">
        <Reveal delay={600}>
          <div className="flex xl:flex-row flex-col  gap-5 md:gap-7 px-4 ">
            <Card
              cover={one}
              desc="Capitalize on your surplus food and save sunk costs."
            />
            <Star />
            <Card
              cover={two}
              desc="Become the go-to eatery for sustainability pro audience."
            />
            <Star />
            <Card
              cover={three}
              desc="Save our planet by curbing pollution generated by food waste."
            />
          </div>
        </Reveal>
      </div>
    </section>
  )
}

function Star({ isHidden = false }: { isHidden?: boolean }) {
  return (
    <div className="grid place-content-center">
      <svg
        width="28"
        height="29"
        viewBox="0 0 28 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.9311 1.31686C13.3181 0.688453 14.2317 0.688452 14.6187 1.31686L19.2252 8.79685C19.3064 8.92874 19.4175 9.03976 19.5494 9.12098L27.0294 13.7275C27.6578 14.1145 27.6578 15.0281 27.0293 15.4151L19.5494 20.0216C19.4175 20.1028 19.3064 20.2138 19.2252 20.3457L14.6187 27.8257C14.2317 28.4541 13.3181 28.4541 12.9311 27.8257L8.32459 20.3457C8.24337 20.2138 8.13236 20.1028 8.00046 20.0216L0.520472 15.4151C-0.107933 15.0281 -0.107935 14.1145 0.52047 13.7275L8.00046 9.12098C8.13235 9.03976 8.24337 8.92874 8.32459 8.79685L12.9311 1.31686Z"
          fill="#F7974F"
        />
      </svg>
    </div>
  )
}
function Card({ cover, desc }: { cover: string; desc: string }) {
  return (
    <article
      className="min-h-full max-w-lg mx-auto self-stretch flex-1 w-full orange-outline-border pt-6 md:pt-8  lg:pt-12 pb-6  lg:pb-8"
      style={{ background: 'linear-gradient(180deg, #FFF 0%, #FFF6EE 100%)' }}
    >
      <div className="grid place-items-center place-content-center">
        <img
          src={cover}
          className="w-full mx-auto  max-w-[160px] md:max-w-sm lg:max-w-none h-full object-bottom  object-contain lg:w-full"
          alt={desc}
          aria-hidden
        />
      </div>
      <div className=" px-4  text-center mt-2 lg:mt-10 xl:mt-12">
        <h6 className="leading-normal font-semibold max-w-xs mx-auto w-11/12">
          {desc}
        </h6>
      </div>
    </article>
  )
}
