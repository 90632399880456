import React, { useLayoutEffect } from 'react'
import ContactForm from 'organisms/contact_form'
import Banner from './01_banner'
import FAQ from './02_faq'
import ContactInfo from './03_info'

export default function Contact() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    // use a fragment to wrap
    <div
      style={{
        background: 'linear-gradient(180deg, #CBDBE5 0%, #F0EAEC 100%)',
      }}
      className="bg-cover bg-center"
    >
      <Banner />
      <ContactForm isPurposeField />
      <FAQ />
      <ContactInfo />
      {/*
      
     */}
    </div>
  )
}
