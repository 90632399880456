import React, { useState } from 'react'
import eatries from 'assets/home/Eateries.webp'
import foodies from 'assets/home/Foodies.webp'
import environment from 'assets/home/Environment.webp'

import { gsap } from 'gsap'
import { SwitchTransition, Transition } from 'react-transition-group'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, EffectCreative } from 'swiper'
import 'swiper/css'

const data = [
  {
    title: 'Foodies',
    subtitle: "Eat like there's a tomorrow.",
    desc: 'Want to make a difference and also satisfy your taste buds? With discounts on fresh surplus meals from a wide range of eateries, you can savor unique flavors and feel like a superhero saving the planet.',
    img: foodies,
  },
  {
    title: 'Eateries',
    subtitle: 'Cook up a storm with sustainability!',
    desc: 'Surplus food leads to an alarming global waste of 923 million tonnes in restaurants. We help reduce this and help you gain a wider audience, boost profits, recover sunk costs, and make a positive impact one meal at a time.',
    img: eatries,
  },
  {
    title: 'Environment',
    subtitle: 'Together let’s make a difference.',
    desc: "Did you know landfills are responsible for 11% of global methane emissions? If we don't act fast, that number could skyrocket to a staggering 70% by 2050! That's why we've taken it upon ourselves to create a healthier, more sustainable future by reducing food waste.",
    img: environment,
  },
]

const exitAnim = (node: any) => {
  gsap.to(node, {
    duration: 0.3,
    yPercent: 10,
    opacity: 0,
    delay: 0.1,
  })
}

const enterAnim = (node: any) => {
  gsap.fromTo(
    node,
    {
      opacity: 0,
      yPercent: 10,
    },
    {
      yPercent: 0,
      duration: 0.3,
      opacity: 1,
    },
  )
}

export default function WinWin() {
  const [activeIndex, setActiveIndex] = useState(0)

  return (
    <section
      style={{
        background:
          'linear-gradient(180deg, #FFF 0%, #CCDBE5 100%), linear-gradient(360deg, #D2E7BC 0%, #FFF 100%)',
      }}
    >
      <section className="  hidden  lg:flex  flex-col-reverse xl:grid   carousel-wrapper gap-5 lg:gap-10 2xl:gap-16 w-container-lg blade-top-padding-lg blade-bottom-padding-lg ">
        <div className=" grid-cols-2  max-w-[280px] w-full mx-auto xl:hidden grid   place-items-center">
          <button
            type="button"
            className={` ${
              activeIndex === 0 ? 'border-darkGray' : 'border-green'
            }  checking border-[3px] grid place-content-center prev-btn-large rounded-full  h-9 w-9 md:h-12 md:w-12 xl:h-14 xl:w-14`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
              />
            </svg>
          </button>
          <button
            type="button"
            disabled={activeIndex === data.length - 1}
            className={` ${
              activeIndex === data.length - 1
                ? 'border-darkGray'
                : 'border-green'
            } border-[3px] grid place-content-center next-btn-large rounded-full  h-9 w-9 md:h-12 md:w-12 xl:h-14 xl:w-14`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
              />
            </svg>
          </button>
        </div>
        <div className="flex items-center">
          <SwitchTransition>
            <Transition
              appear
              timeout={500}
              in
              key={activeIndex}
              onExit={exitAnim}
              onEnter={enterAnim}
              mountOnEnter
              unmountOnExit
            >
              <div className="  ">
                <h3 className="text-green font-bold">
                  {data[activeIndex].title}
                </h3>
                <h2 className=" font-bold   max-w-xl mt-1 mb-3  md:mb-4 xl:leading-tight">
                  {data[activeIndex].subtitle}
                </h2>
                <h6 className="font-normal max-w-md  mb-6  md:mb-10">
                  {data[activeIndex].desc}
                </h6>
              </div>
            </Transition>
          </SwitchTransition>
        </div>

        <div className=" grid-cols-2 xl:grid hidden   place-items-center">
          <button
            type="button"
            className={` ${
              activeIndex === 0 ? 'border-darkGray' : 'border-green'
            }  checking border-[3px] grid place-content-center prev-btn-large rounded-full  h-9 w-9 md:h-12 md:w-12 xl:h-14 xl:w-14`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
              />
            </svg>
          </button>
          <button
            type="button"
            className={` ${
              activeIndex === data.length - 1
                ? 'border-darkGray'
                : 'border-green'
            } border-[3px] grid place-content-center next-btn-large rounded-full  h-9 w-9 md:h-12 md:w-12 xl:h-14 xl:w-14`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
              />
            </svg>
          </button>
        </div>

        <div>
          <div>
            <Swiper
              speed={1000}
              navigation={{
                nextEl: '.next-btn-large',
                prevEl: '.prev-btn-large',
              }}
              creativeEffect={{
                limitProgress: 3,
                prev: {
                  translate: [0, 0, '-100px'],
                },
                next: {
                  translate: ['100%', 0, 0],
                },
              }}
              onSlideChange={(swiper) => {
                if (swiper.activeIndex !== data.length) {
                  setActiveIndex(swiper.activeIndex)
                } else setActiveIndex(data.length)
              }}
              modules={[Navigation, EffectCreative]}
              spaceBetween={50}
              effect="creative"
              loop={false}
              slidesPerView={1}
            >
              {data.map((elem, index) => {
                const key = `${index}`
                return (
                  <SwiperSlide key={key}>
                    <div className=" max-w-xl mb-20 xl:mb-0 mx-auto xl:max-w-none  grid place-content-center">
                      <img src={elem.img} alt={elem.subtitle} />
                    </div>
                  </SwiperSlide>
                )
              })}
            </Swiper>
          </div>
        </div>
      </section>
      <section className="lg:hidden  block w-container blade-top-padding blade-bottom-padding">
        <div>
          <div className="px-3">
            <Swiper
              speed={1000}
              navigation={{
                nextEl: '.next-btn',
                prevEl: '.prev-btn',
                disabledClass: 'swiper-button-disabled',
              }}
              creativeEffect={{
                limitProgress: 3,
                prev: {
                  translate: [0, 0, '-100px'],
                },
                next: {
                  translate: ['100%', 0, 0],
                },
              }}
              onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
              modules={[Navigation, EffectCreative]}
              spaceBetween={50}
              effect="creative"
              loop={false}
              slidesPerView={1}
            >
              {data.map((elem, index) => {
                const key = `${index}`
                return (
                  <SwiperSlide key={key}>
                    <div className="   grid place-content-center">
                      <img
                        src={elem.img}
                        alt={elem.subtitle}
                        className="h-[500px] w-auto object-contain"
                      />
                    </div>
                  </SwiperSlide>
                )
              })}
            </Swiper>
          </div>
        </div>
        <div className="  md:pt-20">
          <SwitchTransition>
            <Transition
              appear
              timeout={500}
              in
              key={activeIndex}
              onExit={exitAnim}
              onEnter={enterAnim}
              mountOnEnter
              unmountOnExit
            >
              <div className="  ">
                <h3 className="text-green font-bold">
                  {data[activeIndex].title}
                </h3>
                <h2 className=" font-bold   max-w-xl mt-1 mb-3  md:mb-4 xl:leading-tight">
                  {data[activeIndex].subtitle}
                </h2>
                <h6 className="font-normal max-w-md  mb-6  md:mb-10">
                  {data[activeIndex].desc}
                </h6>
              </div>
            </Transition>
          </SwitchTransition>
        </div>
        <div className=" max-w-[120px] grid grid-cols-2 mt-10 mx-auto  place-items-center">
          <button
            type="button"
            className={` ${
              activeIndex === 0 ? 'border-darkGray' : 'border-green'
            }  checking border-[3px] grid place-content-center prev-btn rounded-full  h-12 w-12 xl:h-14 xl:w-14`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
              />
            </svg>
          </button>
          <button
            type="button"
            className={` ${
              activeIndex === data.length - 1
                ? 'border-darkGray'
                : 'border-green'
            } border-[3px] grid place-content-center next-btn rounded-full  h-12 w-12 xl:h-14 xl:w-14`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
              />
            </svg>
          </button>
        </div>
      </section>
    </section>
  )
}
