import React from 'react'
import mission from 'assets/about/mission.svg'
import vision from 'assets/about/vision.svg'

export default function MissionAndVision() {
  return (
    <section
      className=" blade-top-padding-sm blade-bottom-padding-sm"
      style={{
        background:
          'linear-gradient(180deg, rgba(255, 245, 236, 0.00) 0%, #FFF5EC 100%)',
      }}
    >
      <div className=" w-container-lg 2xl:w-container-sm  px-5 md:px-10 lg:px-20 xl:px-28 2xl:px-32">
        <div className="grid  grid-cols-1 lg:grid-cols-2   gap-6 lg:gap-8 xl:gap-10 2xl:gap-16 blade-top-padding blade-bottom-padding-lg blade-top-padding-lg ">
          <Card
            title="Mission"
            desc=" To combat food wastage, enable effortless sustainability, and reduce the environmental impact of food waste in landfills."
            cover={mission}
          />
          <Card
            title="Vision"
            desc=" To build a healthier and more sustainable world with zero food waste, ensuring fresh surplus food reaches consumers."
            cover={vision}
          />
        </div>
      </div>
    </section>
  )
}

type CardProps = {
  title: string
  desc: string
  cover: string
}
function Card({ cover, desc, title }: CardProps) {
  return (
    <article className="max-w-xl mx-auto lg:max-w-none w-full border-2 border-opacity-50 border-orange rounded-xl overflow-hidden ">
      <div
        style={{
          background:
            'linear-gradient(180deg, #FFE2CC 0%, rgba(255, 228, 207,0) 100%)',
        }}
      >
        <img
          src={cover}
          alt="Mission of Good Deed"
          className=" mx-auto px-10 select-none"
        />
      </div>
      <div
        style={{
          backgroundImage:
            'linear-gradient( to bottom, transparent, white 25%,  white 100%',
        }}
      >
        <div className=" px-4 md:px-0 md:w-10/12 mx-auto  mt-4 md:mt-5  xl:mt-4   pb-8 lg:pb-12 xl:pb-10">
          <h3 className="font-extrabold mb-2">{title} </h3>
          <h6 className=" md:block hidden pr-5 md:w-11/12 lg:w-10/12  2xl:w-9/12 max-w-sm leading-relaxed">
            {desc}
          </h6>
          <span className=" md:hidden block text-sm  pr-5 md:w-11/12   lg:w-10/12 2xl:w-9/12 max-w-sm leading-relaxed">
            {desc}
          </span>
        </div>
      </div>
    </article>
  )
}
