import React from 'react'
import catering from 'assets/business/catering.webp'
import dining from 'assets/business/dining.webp'
import hotels from 'assets/business/hotels.webp'
import resturants from 'assets/business/resturant.webp'
import Slide from 'molecules/slide'
import Reveal from 'molecules/reveal'

export default function EcoWarriors() {
  return (
    <section>
      <div
        style={{
          background: 'linear-gradient(180deg, #FFFBF7 0%, #CCDBE5 100%)',
        }}
        className="blade-top-padding-lg blade-bottom-margin-lg blade-top-margin-lg blade-bottom-padding-lg"
      >
        <div className="blade-bottom-padding">
          <div className="text-center px-3 ">
            <Slide>
              <h2 className="md:block hidden font-extrabold text-[#1B3F5B]  mt-4 lg:mt-6 lxl:mt-8 ">
                Attention eco-warriors!
              </h2>
              <h3 className=" md:hidden block font-extrabold text-[#1B3F5B]  mt-4 lg:mt-6 lxl:mt-8 ">
                Attention eco-warriors!
              </h3>
            </Slide>

            <Slide delay={500}>
              <h6 className="mt-4 text-center max-w-2xl mx-auto px-4 leading-relaxed md:block hidden ">
                When it comes to fighting food waste and championing
                sustainability, collaboration knows no bounds.{' '}
              </h6>
            </Slide>

            <span className="mt-2 text-sm md:hidden block text-center  px-3 leading-relaxed">
              When it comes to fighting food waste and championing
              sustainability, collaboration knows no bounds.{' '}
            </span>
          </div>
          <div className="blade-top-padding-lg ">
            <Reveal>
              <div className=" w-container-xl mx-auto  grid grid-cols-1 lg:grid-cols-4 xl:grid-cols-4  gap-y-6 gap-x-2 2xl:gap-10">
                <Card cover={resturants} title="Restaurants and Cafes" />
                <Card cover={hotels} title="Hotels and Resorts" />
                <Card cover={catering} title="Catering Services" />
                <Card cover={dining} title="Corporate Dining " />
              </div>
            </Reveal>
          </div>
        </div>
      </div>
    </section>
  )
}

function Card({ cover, title }: { title: string; cover: string }) {
  return (
    <article className="relative group rounded-full  w-10/12 md:w-full mx-auto h-[24rem] lg:h-[28rem] 2xl:h-[32rem] overflow-hidden transition-all duration-300 ease-in-out hover:shadow-card">
      <div className="absolute top-0 left-0 right-0 overflow-hidden  border-2 border-solid border-white  group-hover:border-orange group-hover:border-opacity-80    transition-colors duration-300 rounded-full bottom-0 h-full w-full">
        <img
          className="h-full w-full object-center object-cover  select-none pointer-events-none"
          src={cover}
          alt={title}
        />
      </div>
      <div
        style={{
          background:
            'linear-gradient( to bottom, transparent 40%, white 80%  , white)',
        }}
        className="absolute top-0 left-0 right-0 bottom-0 h-full w-full  text-center flex items-end justify-center pb-14 lg:pb-16 font-semibold"
      >
        <h6 className="group-hover:text-paleGreen transition-300 ease-in-out transition-all">
          {title}
        </h6>
      </div>
    </article>
  )
}
