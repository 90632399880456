import React, { useLayoutEffect } from 'react'
import Banner from './01_banner'
import BagGrid from './02_bagGrid'
import Lifeline from './03_lifeline'
import MissionAndVision from './04_mission_vision'
import Form from './05_form'

export default function About() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div>
      <Banner />
      <BagGrid />
      <Lifeline />
      <MissionAndVision />
      <Form />
    </div>
  )
}
