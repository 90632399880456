import { collection, addDoc, getDocs } from 'firebase/firestore'
import { FormFieldSchema } from 'organisms/contact_form'

import { firestore } from './index'

export type FormFields = FormFieldSchema & { purpose: string }
export const submitForm = async (
  formData: FormFields,
): Promise<{ isDone: boolean }> => {
  try {
    await addDoc(collection(firestore, 'submissions'), formData)
    return { isDone: true }
  } catch (error) {
    console.error('Error submitting form:', error)
    return { isDone: false }
  }
}

export const fetchFormSubmissions = async (): Promise<FormFields[]> => {
  try {
    const querySnapshot = await getDocs(collection(firestore, 'submissions'))
    const submissionsList: FormFields[] = []
    querySnapshot.forEach((doc) => {
      submissionsList.push(doc.data() as FormFields)
    })
    return submissionsList
  } catch (error) {
    console.error('Error fetching submissions:', error)
    return []
  }
}
