import React from 'react'
import logoWithoutText from 'assets/home/logo_center.webp'

export default function SuperPower() {
  return (
    <section className="blade-top-padding-lg">
      <section className="flex lg:flex-row flex-col gap-5 xl:gap-12 2xl:gap-16 items-center blade-bottom-padding-sm blade-top-padding-lg">
        <div className="flex-1 grid w-11/12  md:w-9/12 xl:w-full mr-auto  py-8 md:py-16 xl:py-20 2xl:py-36 pr-20  pl-8 lg:pl-12 xl:pl-20  border-r-2 border-t-2 border-b-2 border-solid border-white rounded-tr-full rounded-br-full">
          <h3 className="font-extrabold mb-3 xl:block hidden">Our goal!</h3>
          <h4 className="font-extrabold mb-3 xl:hidden block">Our goal!</h4>
          <span className=" text-sm xl:text-base 2xl:text-xl w-11/12 mr-auto leading-normal xl:leading-normal max-w-lg">
            We are on a mission to create a world where food waste is
            eradicated, resulting in a beneficial influence on the environment
            and society. We&apos;re here to make it as easy as pie for folks
            like you to join us in the fight against waste by rescuing delicious
            food, saving money, and promoting affordable sustainability.
          </span>
        </div>
        <div className="overflow-hidden lg:block hidden basis-52 grow-0 shrink-0">
          <div className="grid place-content-center">
            <img
              src={logoWithoutText}
              alt="Good Deed's logo along with text."
              className="w-full h-full object-contain object-center"
            />
          </div>
        </div>
        <div className="flex-1 grid  w-11/12  md:w-9/12 xl:w-full ml-auto   py-10  md:py-16 xl:py-20 2xl:py-36 pl-24 2xl:pl-32  pr-8 xl:pr-12  border-l-2 border-t-2 border-b-2 border-solid border-white rounded-tl-full rounded-bl-full">
          <h3 className="font-extrabold text-orange mb-3 xl:block hidden ">
            Our superpower?
          </h3>
          <h4 className="font-extrabold text-orange mb-3 xl:hidden block ">
            Our superpower?
          </h4>
          <span className=" text-sm xl:text-base max-w-lg 2xl:text-xl ">
            Empowering you to make conscious choices while also giving the
            HoReCa (Hotels- Restaurants and Caterers) industry a much-needed
            boost by saving them from sunk costs. Together, let&apos;s embark on
            a hilarious and delicious journey towards a future where food waste
            is banished, and resources are utilized with superhero efficiency.
          </span>
        </div>
      </section>
    </section>
  )
}
