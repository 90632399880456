import React from 'react'
import meltingEarth from 'assets/home/earth_melting.svg'

export default function Globe() {
  return (
    <section className="  blade-top-padding lg:blade-bottom-padding-lg blade-top-padding-lg  dark-orange-bg-mesh  bg-no-repeat ">
      <div className=" lg:flex-row flex flex-col gap-8 w-container-lg blade-top-padding-sm blade-bottom-padding-sm">
        <div className="basis-0 grow shrink   flex justify-center flex-col ">
          <h3 className="text-white font-extrabold">
            Savour, don&apos;t scatter
          </h3>
          <h2 className="font-extrabold mt-2 mb-6  text-green w-11/12 ">
            Earth is kind of <br className="md:block hidden" />a big deal.
          </h2>
          <h5 className="text-white max-w-xl  pr-3 md:w-11/12 font-semibold ">
            Food wastage isn&apos;t just your average everyday problem—it&apos;s
            a full-blown global crisis! The most significant chunk of this waste
            is being contributed by eateries, that are forced to throw freshly
            cooked surplus food.
          </h5>
        </div>
        <div className="basis-32 grow sherink">
          <div className="sm:mt-10 lg:mt-0 mt-5  ">
            <img
              src={meltingEarth}
              className=" max-w-xl lg:max-w-none mx-auto  w-full h-full "
              alt="Melting Earth depicting environmental problems."
            />
          </div>
        </div>
      </div>
    </section>
  )
}
