import React, { useEffect, MouseEvent, useRef } from 'react'
import mobileApp from 'assets/home/mobile_app/bg_img.webp'
import grassFore from 'assets/home/mobile_app/front_flowers.webp'
import mobile from 'assets/home/mobile_app/mobile.webp'

import { gsap } from 'gsap'

export default function MobileApp() {
  const imgWrapper = useRef<HTMLImageElement>(null)

  const handleMouse = (event: MouseEvent) => {
    if (imgWrapper.current) {
      const maxX = gsap.getProperty(imgWrapper.current, 'width') as number
      const percent = gsap.utils.normalize(0, window.innerWidth, event.pageX)

      gsap.to(imgWrapper.current, {
        duration: 0.5,
        x: percent * maxX * 0.05 - (maxX * 0.05) / 2,
        overwrite: true,
      })
    }
  }
  return (
    <section
      onMouseMove={(e: MouseEvent) => {
        handleMouse(e)
      }}
      className="blade-top-padding blade-top-margin blade-bottom-margin blade-bottom-padding  px-3 sm:w-container-sm  "
    >
      <div className=" blade-top-margin   h-[10rem] sm:h-[15rem] md:h-[17rem] lg:h-[20rem] xl:h-[24rem] 2xl:h-[34rem]   rounded-full img-wrapper  relative ">
        <img
          src={mobileApp}
          alt="Mobile App coming soon."
          className="w-full h-full absolute left-0 right-0 bottom-0 top-0 rounded-full  "
        />
        <div className="absolute left-0 right-0 bottom-0 top-0 overflow-hidden rounded-full">
          <img
            ref={imgWrapper}
            src={grassFore}
            alt="Mobile App coming soon."
            className="w-full h-full absolute left-0 right-0 bottom-0 top-0 scale-105 object-cover "
          />
        </div>
        <div className="absolute bottom-0 top-0 left-0 right-0 flex items-end justify-center  ">
          <img
            src={mobile}
            className="object-contain mx-auto  h-[14rem] sm:h-[20rem] md:h-[22rem] lg:h-[26rem] xl:h-[30rem] 2xl:h-[45rem] translate-y-[1px]"
            alt="fnklenf"
          />
        </div>
      </div>
    </section>
  )
}
