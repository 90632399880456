import React from 'react'
import bag from 'assets/about/bag.webp'
import Reveal from 'molecules/reveal'
import Slide from 'molecules/slide'

export default function BagGrid() {
  return (
    <section className="w-container md:blade-bottom-margin-lg blade-bottom-padding-lg">
      <div className="grid grid-cols-1 lg:grid-cols-2   md:gap-5 lg:gap-12 xl:gap-16 2xl:gap-20">
        <div className="grid place-content-center]">
          <Slide>
            <img
              src={bag}
              alt="Bag with branding of the Good Deed"
              className=" max-h-96 mx-auto md:max-h-[30rem] lg:max-h-full"
            />
          </Slide>
        </div>
        <div className="blade-top-padding blade-bottom-padding flex items-center">
          <Slide className='font-semibold max-w-xl  md:text-center lg:text-left text-left"'>
            <h4>
              This fact, along with days of research and conviction to change
              the world (and some extra COVID motivation), led to the birth of
              Good Deed.
            </h4>
          </Slide>
        </div>
      </div>
    </section>
  )
}
