// eslint-ignore jsx-a11y/media-has-caption
import React, { useRef, useState, useEffect, MouseEvent } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import videoThumb from 'assets/home/video_thumb.webp'
import { gsap } from 'gsap'
import Reveal from 'molecules/reveal'
import videoFile from 'assets/home/banner_video.mp4'

export default function Banner() {
  const [isOpen, setIsOpen] = useState(false)
  const videoRef = useRef<HTMLVideoElement>(null)
  const handleVideo = () => {
    if (videoRef.current) {
      videoRef.current.requestFullscreen()
      videoRef.current.play()
    }
  }

  const handlePlay = () => {
    setIsOpen(true)
  }

  const closeModal = () => {
    setIsOpen(false)
    if (videoRef.current) {
      videoRef.current.pause()
    }
  }

  const imgWrapper = useRef<HTMLImageElement>(null)
  const btnRef = useRef<HTMLDivElement>(null)
  const handleMouse = (event: MouseEvent) => {
    if (imgWrapper.current && btnRef.current) {
      if (imgWrapper.current) {
        const divRect = imgWrapper.current.getBoundingClientRect()
        const centerX = divRect.left + divRect.width / 2
        const centerY = divRect.top + divRect.height / 2
        const translateX = event.clientX - centerX
        const translateY = event.clientY - centerY
        gsap.to(imgWrapper.current, {
          duration: 0.5,
          x: translateX * 0.05,
          y: translateY * 0.05,
          overwrite: true,
        })
        gsap.to(btnRef.current, {
          duration: 0.5,
          x: translateX * 0.1,
          y: translateY * 0.1,
          overwrite: true,
        })
      }
    }
  }

  const handleMouseOut = () => {
    gsap.to(imgWrapper.current, {
      duration: 0.5,
      x: 0,
      y: 0,
      overwrite: true,
    })
    gsap.to(btnRef.current, {
      duration: 0.5,
      x: 0,
      y: 0,
      overwrite: true,
    })
  }
  return (
    <section
      onMouseMove={(e: MouseEvent) => {
        handleMouse(e)
      }}
      onMouseOut={() => {
        handleMouseOut()
      }}
      onBlur={handleMouseOut}
    >
      <div className="blade-top-padding-lg blade-bottom-padding-lg mb-4 ">
        <Reveal>
          <h2 className=" headline font-bold text-center leading-normal px-5  sm:w-10/12 lg:w-full mx-auto blade-top-padding-lg blade-top-margin-lg md:block hidden">
            Commited to building a healthier, <br className="lg:block hidden" />{' '}
            more sustainable future by <br className="lg:block hidden" />
            <span className="text-orange"> Reducing Food Waste.</span>
          </h2>
          <h3 className="font-bold text-center leading-normal px-5  sm:w-10/12 lg:w-full mx-auto blade-top-padding-lg blade-top-margin-lg md:hidden block">
            Commited to building a healthier, <br className="lg:block hidden" />{' '}
            more sustainable future by <br className="lg:block hidden" />
            <span className="text-orange"> Reducing Food Waste.</span>
          </h3>
        </Reveal>
      </div>
      <Reveal>
        <div className="relative h-72 md:h-96 lg:h-[30rem] xl:h-[38rem] 2xl:h-[50rem] img-wrapper rounded-3xl md:rounded-full overflow-hidden w-container-sm  mx-auto ">
          <div
            ref={imgWrapper}
            className="absolute left-0 right-0 top-0 bottom-0  h-full w-full z-0"
          >
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            <img
              className="w-full h-full scale-110"
              src={videoThumb}
              alt="Poster for introduction video for gooddeed."
            />
          </div>
          {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
          <div
            ref={btnRef}
            className="absolute top-0 left-0 right-0 bottom-0 h-full w-full grid place-content-center"
          >
            <button
              type="button"
              onClick={handlePlay}
              className="stroke-[4px] hover:stroke-[6px] rounded-full p-2 "
            >
              <svg
                className="w-auto h-24 lg:w-32 xl:h-36"
                viewBox="0 0 172 172"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M81.6511 2.84285C84.3855 1.22561 87.7835 1.2256 90.5179 2.84285L105.665 11.8019C106.913 12.5402 108.326 12.9551 109.776 13.0088L127.362 13.6613C130.537 13.7791 133.395 15.6162 134.821 18.455L142.72 34.1811C143.371 35.4771 144.336 36.5899 145.526 37.4187L159.968 47.4755C162.575 49.2909 163.986 52.3819 163.651 55.541L161.794 73.0412C161.641 74.4834 161.851 75.9409 162.404 77.2816L169.116 93.5497C170.327 96.4864 169.844 99.8499 167.854 102.326L156.83 116.044C155.922 117.175 155.31 118.514 155.051 119.941L151.902 137.256C151.334 140.381 149.109 142.949 146.096 143.957L129.406 149.537C128.03 149.997 126.791 150.794 125.802 151.854L113.792 164.717C111.624 167.039 108.364 167.997 105.284 167.215L88.2265 162.887C86.8208 162.53 85.3482 162.53 83.9425 162.887L66.8847 167.215C63.8055 167.997 60.5451 167.039 58.3771 164.717L46.3674 151.854C45.3777 150.794 44.1389 149.997 42.7634 149.537L26.0733 143.957C23.0604 142.949 20.8352 140.381 20.2668 137.256L17.1182 119.941C16.8587 118.514 16.247 117.175 15.3385 116.044L4.31503 102.326C2.3251 99.8499 1.84151 96.4864 3.05316 93.5498L9.7653 77.2816C10.3184 75.9409 10.528 74.4834 10.375 73.0412L8.518 55.541C8.18278 52.3819 9.59438 49.2909 12.2014 47.4755L26.6432 37.4187C27.8334 36.5899 28.7977 35.4771 29.4487 34.1811L37.3478 18.455C38.7737 15.6162 41.6323 13.7791 44.8069 13.6613L62.3933 13.0088C63.8426 12.9551 65.2555 12.5402 66.5038 11.8019L81.6511 2.84285Z"
                  fill="#F7974F"
                />
                <circle
                  cx="86.0859"
                  cy="86.0896"
                  r="66.895"
                  className="transition-all duration-200 ease-in-out"
                  stroke="white"
                  strokeWidth="inherit"
                />
                <path
                  d="M78.4121 68.8725C76.1261 67.5009 73.2178 69.1475 73.2178 71.8135V102.881C73.2178 105.547 76.1261 107.194 78.4121 105.822L104.302 90.2884C106.522 88.9563 106.522 85.7385 104.302 84.4064L78.4121 68.8725Z"
                  fill="white"
                />
              </svg>
            </button>
          </div>
        </div>
      </Reveal>
      <Transition appear show={isOpen} as={React.Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black opacity-50" />
            </Transition.Child>

            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-4xl h-auto border-4 border-solid  border-white my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-md">
                <div className="flex flex-col h-full  oveflow-hidden relative">
                  <div className="flex-0 grid place-content-end absolute top-2 z-10 right-2 left-auto bottom-auto">
                    <button
                      type="submit"
                      onClick={closeModal}
                      className=" grid place-content-center bg-lightOrange p-3 hover:bg-lightOrange focus-visible:bg-lightOrange rounded-full"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                  <div className="flex-1  overflow-hidden rounded-md">
                    {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                    <video
                      ref={videoRef}
                      controls
                      className="w-full h-full object-cover object-center"
                      src={videoFile}
                      autoPlay
                      loop
                    />
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </section>
  )
}

/*



*/
