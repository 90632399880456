import React, { useLayoutEffect } from 'react'
import RenderMarkdown from 'organisms/markdown_renderer'

const content = `

Last updated on **March 31, 2023**

#### I. Acceptance of Terms

Thank you for using Good Deed. These Terms of Service (the "Terms") are intended to make you aware of your legal rights and responsibilities with respect to your access to and use of the Good Deed website at [gooddeed.in](https://gooddeed.in) (the "Site") and any related mobile or software applications ("Good Deed Platform"), including but not limited to the delivery of information via the website, whether existing now or in the future, that link to the Terms (collectively, the "Services").

These Terms are effective for all existing and future Good Deed customers, including but not limited to users having access to the 'Restaurant Business Page' to manage their claimed business listings.

Please read these Terms carefully. By accessing or using the Good Deed Platform, you are agreeing to these Terms and concluding a legally binding contract with Good Deed Limited (formerly known as Good Deed Private Limited and Good Deed Media Private Limited) and/or its affiliates (excluding Good Deed Foods Private Limited) (hereinafter collectively referred to as "Good Deed"). You may not use the Services if you do not accept the Terms or are unable to be bound by the Terms. Your use of the Good Deed Platform is at your own risk, including the risk that you might be exposed to content that is objectionable or otherwise inappropriate.

In order to use the Services, you must first agree to the Terms. You can accept the Terms by:

1. Clicking to accept or agree to the Terms, where it is made available to you by Good Deed in the user interface for any particular Service; or

2. Actually using the Services. In this case, you understand and agree that Good Deed will treat your use of the Services as acceptance of the Terms from that point onwards.

#### II. Definitions

##### Customer

"Customer" or "You" or "Your" refers to you, as a customer of the Services. A customer is someone who accesses or uses the Services for the purpose of sharing, displaying, hosting, publishing, transacting, or uploading information, views, pictures, or other content, and includes other persons jointly participating in using the Services, including without limitation a user having access to a 'Restaurant Business Page' to manage claimed business listings or otherwise.

##### Content

"Content" will include (but is not limited to) reviews, images, photos, audio, video, location data, nearby places, and all other forms of information or data. "Your content" or "Customer Content" means content that you upload, share, or transmit to, through, or in connection with the Services, such as likes, ratings, reviews, images, photos, messages, chat communication, profile information, or any other materials that you publicly display or display in your account profile. "Good Deed Content" means content that Good Deed creates and makes available in connection with the Services, including but not limited to visual interfaces, interactive features, graphics, design, compilation, computer code, products, software, aggregate ratings, reports, and other usage-related data in connection with activities associated with your account and all other elements and components of the Services, excluding Your Content and Third-Party Content. "Third-Party Content" means content that comes from parties other than Good Deed or its Customers, such as Restaurant Partners, and is available on the Services.

##### Restaurant(s)

"Restaurant" means the restaurants listed on the Good Deed Platform.

#### III. Eligibility to Use the Services

You hereby represent and warrant that you are at least eighteen (18) years of age or above and are fully able and competent to understand and agree to the terms, conditions, obligations, affirmations, representations, and warranties set forth in these Terms.

Compliance with Laws. You are in compliance with all laws and regulations in the country in which you live when you access and use the Services. You agree to use the Services only in compliance with these Terms and applicable law, and in a manner that does not violate our legal rights or those of any third party(ies).

#### IV. Changes to the Terms

Good Deed may vary, amend, change, or update these Terms from time to time entirely at its own discretion. You shall be responsible for checking these Terms from time to time and ensuring continued compliance with these Terms. Your use of the Good Deed Platform after any such amendment or change in the Terms shall be deemed as your express acceptance of such amended/changed terms, and you also agree to be bound by such changed/amended Terms.

#### V. Translation of the Terms

Good Deed may provide a translation of the English version of the Terms into other languages. You understand and agree that any translation of the Terms into other languages is only for your convenience and that the English version shall govern the terms of your relationship with Good Deed. Furthermore, if there are any inconsistencies between the English version of the Terms and its translated version, the English version of the Terms shall prevail over others.

#### VI. Provision of the Services Being Offered by Good Deed

Good Deed is constantly evolving to provide the best possible experience and information to its Customers. You acknowledge and agree that the form and nature of the Services which Good Deed provides may require affecting certain changes in it. Therefore, Good Deed reserves the right to suspend, cancel, or discontinue any or all products or services at any time without notice, make modifications and alterations in any or all of its contents, products, and services contained on the site without any prior notice.

We, the software, or the software application store that makes the software available for download may include functionality to automatically check for updates or upgrades to the software. Unless your device, its settings, or computer software does not permit transmission or use of upgrades or updates, you agree that we or the applicable software or software application store may provide notice to you of the availability of such upgrades or updates and automatically push such upgrade or update to your device or computer from time to time. You may be required to install certain upgrades or updates to the software to continue to access or use the Services, or portions thereof (including upgrades or updates designed to correct issues with the Services). Any updates or upgrades provided to you by us under the Terms shall be considered part of the Services.

You acknowledge and agree that if Good Deed disables access to your account, you may be prevented from accessing the Services, your account details, or any files or other content contained in your account.

You acknowledge and agree that while Good Deed may not currently have set a fixed upper limit on the number of transmissions you may send or receive through the Services, Good Deed may set such fixed upper limits at any time, at Good Deed's discretion.

In our effort to continuously improve the Good Deed Platform and Services, we undertake research and conduct experiments from time to time on various aspects of the Services and offerings, including our apps, websites, user interface, and promotional campaigns. As a result, some Customers may experience features differently than others at any given time. This is for making the Good Deed Platform better, more convenient and easy to use, improving Customer experience, enhancing the safety and security of our services and offerings, and developing new services and features.

By using Good Deed's services, you agree to the following disclaimers:

The content on these services is for informational purposes only. Good Deed disclaims any liability for any information that may have become outdated since the last time the particular piece of information was updated. Good Deed reserves the right to make changes and corrections to any part of the content on these services at any time without prior notice. Good Deed does not guarantee the quality of the goods, the prices listed in menus, or the availability of all menu items at any restaurant/merchant. Unless stated otherwise, all pictures and information contained on these services are believed to be owned by or licensed to Good Deed. Please email a takedown request (by using the "Contact Us" link on the home page) to the webmaster if you are the copyright owner of any content on these services and you think the use of the above material violates your copyright in any way. Please indicate the exact URL of the webpage in your request. All images shown here have been digitized by Good Deed. No other party is authorized to reproduce or republish these digital versions in any format whatsoever without the prior written permission of Good Deed.

Any certification, licenses, or permits ("Certification") or information regarding such certification that may be displayed on the restaurant's listing page on the Good Deed platform is for informational purposes only. Such certification is displayed by Good Deed on an 'as available' basis that is provided to Good Deed by the restaurant partner(s)/merchant(s). Good Deed does not make any warranties about the validity, authenticity, reliability, and accuracy of such certification or any information displayed in this regard. Any reliance by a customer upon the certification or information thereto shall be strictly at such customer's own risk, and Good Deed in no manner shall assume any liability whatsoever for any losses or damages in connection with the use of this information or for any inaccuracy, invalidity, or discrepancy in the certification or non-compliance of any applicable local laws or regulations by the restaurant partner/merchant.

Good Deed reserves the right to charge a subscription and/or membership fee in respect of any of its products or services and/or any other charge or fee on a per order level from customers, in respect of any of its products or services on the Good Deed platform anytime in the future.

Good Deed may from time to time introduce referral and/or incentive-based programs for its customers (Program). These programs may be governed by their respective terms and conditions. By participating in the program, customers are bound by the program terms and conditions as well as the Good Deed platform terms. Furthermore, Good Deed reserves the right to terminate/suspend the customer's account and/or credits/points earned and/or participation of the customer in the program if Good Deed determines in its sole discretion that the customer has violated the rules of the program and/or has been involved in activities that are in contravention of the program terms and/or Good Deed platform terms or has engaged in activities which are fraudulent/unlawful in nature. Furthermore, Good Deed reserves the right to modify, cancel, and discontinue its program without notice to the customer.

Good Deed may from time to time offer customers credits, promo codes, vouchers, or any other form of cashback that Good Deed may decide at its discretion. Good Deed reserves the right to modify, convert, cancel, and/or discontinue such credits, promo codes, or vouchers as it may deem fit.

I. Use of services by you or customer

###### Good Deed customer account including 'Claim Your Business Listing' access

a. You must create an account to use some of the features offered by the services, including without limitation 'claim your business listing' on the services. Use of any personal information you provide to us during the account creation process is governed by our privacy policy. You must keep your password confidential, and you are solely responsible for maintaining the confidentiality and security of your account, all changes and updates submitted through your account, and all activities that occur in connection with your account.

b. You may also be able to register to use the services by logging into your account with your credentials from certain third-party social networking sites (e.g., Facebook). You confirm that you are the owner of any such social media account and that you are entitled to disclose your social media login information to us. You authorize us to collect your authentication information and other information that may be available on or through your social media account consistent with your applicable settings and instructions.

c. In creating an account and/or claiming your business's listing, you represent to us that all information provided to us in such a process is true, accurate, and correct, and that you will update your information as and when necessary to keep it accurate. If you are creating an account or claiming a business listing, then you represent to us that you are the owner or authorized agent of such business. You may not impersonate someone else, create or use an account for anyone other than yourself, provide an email address other than your own, create multiple accounts or business listings except as otherwise authorized by us, or provide or use false information to obtain access to a business's listing on the services that you are not legally entitled to claim. You acknowledge that any false claiming of a business listing may cause Good Deed or third parties to incur substantial economic damages and losses for which you may be held liable and accountable.

d. You are also responsible for all activities that occur in your account. You agree to notify us immediately of any unauthorized use of your account to enable us to take necessary corrective action. You also agree that you will not allow any third party to use your Good Deed account for any purpose and that you will be liable for such unauthorized access.

e. By creating an account, you agree to receive certain communications in connection with the Good Deed platform or services. For example, you might receive comments from other customers or other customers may follow the activity to do on your account. You can opt-out or manage your preferences regarding non-essential communications through account settings.

###### Other terms

a. In order to connect you to certain restaurants, we provide value-added telephony services through our phone lines, which are displayed on the specific restaurant listing page on the Good Deed platform, which connect directly to restaurants' phone lines. We record all information regarding this call, including the voice recording of the conversation between you and the restaurant (for internal billing tracking purposes and customer service improvement at the restaurant's end). If you do not wish for your information to be recorded in such a manner, please do not use the telephone services provided by Good Deed. You explicitly agree and permit Good Deed to record all this information when you avail of the telephony services through the Good Deed provided phone lines on the Good Deed platform.

b. You agree to use the services only for purposes that are permitted by (a) the Terms and (b) any applicable law, regulation, or generally accepted practices or guidelines in the relevant jurisdictions.

c. You agree to use the data owned by Good Deed (as available on the services or through any other means like API, etc.) only for personal use/purposes and not for any commercial use (other than in accordance with 'Claim Your Business Listing' access) unless agreed to by/with Good Deed in writing.

d. Third-party content, including content posted by our customers or restaurant partners, does not reflect our views or those of our parent, subsidiary, affiliate companies, branches, employees, officers, directors, or shareholders. In addition, none of the content available through the services is endorsed or certified by the providers or licensors of such third-party content. We assume no responsibility or liability for any of your content or any third-party content.

e. You further acknowledge and agree that Good Deed is not liable for any loss or damage that may be incurred by you as a result of the availability of those external sites or resources, or as a result of any reliance placed by you on the completeness, accuracy, or existence of any advertising, products, or other materials on or available from such websites or resources. Without limiting the generality of the foregoing, we expressly disclaim any liability for any offensive, defamatory, illegal, invasive, unfair, or infringing content provided by third parties.

###### Customer Reviews

a. Customer reviews or ratings for restaurants do not reflect the opinion of Good Deed. Good Deed receives multiple reviews or ratings for restaurants by customers, which reflect the opinions of the customers. It is pertinent to state that each and every review posted on Good Deed is the personal opinion of the customer/reviewer only. Good Deed is a neutral platform, which solely provides a means of communication between customers/reviewers, including customers or restaurant owners/representatives with access to the restaurant business page. The advertisements published on the Good Deed platform are independent of the reviews received by such advertisers.

b. We are a neutral platform, and we don't arbitrate disputes. However, in case someone writes a review that the restaurant does not consider to be true, the best option for the restaurant representative would be to contact the reviewer or post a public response in order to clear up any misunderstandings. If the restaurant believes that any particular customer's review violates any of Good Deed's policies, the restaurant may write to us at [neutrality@gooddeed.in](mailto:neutrality@gooddeed.in) and bring such violation to our attention. Good Deed may remove the review in its sole discretion if the review is in violation of the terms, content guidelines, and policies or otherwise harmful to the services.

######  Content Guidelines and Privacy Policy


**Content Guidelines**

You represent that you have read, understood, and agreed to our guidelines and policies related to content.

**Privacy Policy**

You represent that you have read, understood, and agreed to our privacy policy. Please note that we may disclose information about you to third parties or government authorities if we believe that such a disclosure is reasonably necessary to (i) take action regarding suspected illegal activities; (ii) enforce or apply our terms and privacy policy; (iii) comply with legal process or other government inquiry, such as a search warrant, subpoena, statute, judicial proceeding, or other legal process/notice served on us; or (iv) protect our rights, reputation, and property, or that of our customers, affiliates, or the general public.

###### Restrictions on Use

Without limiting the generality of these terms, in using the services, you specifically agree not to post or transmit any content (including a review) or engage in any activity that, in our sole discretion:

a. Violates our guidelines and policies;

b. Is harmful, threatening, abusive, harassing, tortious, indecent, defamatory, discriminatory, vulgar, profane, obscene, libelous, hateful, or otherwise objectionable, invasive of another's privacy, relating to or encouraging money laundering or gambling;

c. Constitutes an inauthentic or knowingly erroneous review or does not address the goods and services, atmosphere, or other attributes of the business you are reviewing.

d. Contains material that violates the standards of good taste or the standards of the services;

e. Violates any third-party right, including but not limited to the right of privacy, right of publicity, copyright, trademark, patent, trade secret, or any other intellectual property or proprietary rights;

f. Accuses others of illegal activity or describes physical confrontations;

g. Alleges any matter related to health code violations requiring healthcare department reporting. Refer to our guidelines and policies for more details about health code violations.

h. Is illegal or violates any federal, state, or local law or regulation (for example, by disclosing or trading on inside information in violation of securities law);

i. Attempts to impersonate another person or entity;

j. Disguises or attempts to disguise the origin of your content, including but not limited to by: (i) submitting your content under a false name or false pretenses; or (ii) disguising or attempting to disguise the IP address from which your content is submitted;

k. Constitutes a form of deceptive advertisement or causes, or is a result of, a conflict of interest;

l. Is commercial in nature, including but not limited to spam, surveys, contests, pyramid schemes, postings or reviews submitted or removed in exchange for payment, postings or reviews submitted or removed by or at the request of the business being reviewed, or other advertising materials;

m. Asserts or implies that your content is in any way sponsored or endorsed by us;

n. Contains material that is not in English or, in the case of products or services provided in foreign languages, the language relevant to such products or services;

o. Falsely states, misrepresents, or conceals your affiliation with another person or entity;

p. Accesses or uses the account of another customer without permission;

q. Distributes computer viruses or other code, files, or programs that interrupt, destroy, or limit the functionality of any computer software or hardware or electronic communications equipment;

r. Interferes with, disrupts, or destroys the functionality or use of any features of the services or the servers or networks connected to the services;

s. "Hacks" or accesses without permission our proprietary or confidential records, records of another customer, or those of anyone else;

t. Violates any contract or fiduciary relationship (for example, by disclosing proprietary or confidential information of your employer or client in breach of any employment, consulting, or non-disclosure agreement);

u. Decompiles, reverse engineers, disassembles, or otherwise attempts to derive source code from the services;

v. Removes, circumvents, disables, damages, or otherwise interferes with security-related features or features that enforce limitations on the use of the services;

w. Violates the restrictions in any robot exclusion headers on the services, if any, or bypasses or circumvents other measures employed to prevent or limit access to the services;

x. Collects, accesses, or stores personal information about other customers of the services;

y. Is posted by a bot;

z. Harms minors in any way.

AA. Threatens the unity, integrity, defense, security, or sovereignty of India or of the country of use, friendly relations with foreign states, or public order; or causes incitement to the commission of any cognizable offense or prevents investigation of any offense or insults any other nation.

AB. Modifies, copies, scrapes, or crawls; displays, publishes, licenses, sells, rents, leases, lends, transfers, or otherwise commercializes any rights to the Services or Our Content; or

AC. Attempts to do any of the foregoing.

AD. Is patently false and untrue, and is written or published in any form with the intent to mislead or harass a person, entity, or agency for financial gain or to cause any injury to any person.

You acknowledge that Good Deed has no obligation to monitor your – or anyone else's – access to or use of the Services for violations of the Terms or to review or edit any content. However, we have the right to do so for the purpose of operating and improving the Services (including, without limitation, fraud prevention, risk assessment, investigation, and customer support purposes), to ensure your compliance with the Terms, and to comply with applicable law or the order or requirement of legal process, a court, consent decree, administrative agency, or other governmental body.

You hereby agree and assure Good Deed that the Good Deed Platform/Services shall be used for lawful purposes only and that you will not violate laws, regulations, ordinances, or other such requirements of any applicable central, federal, state, or local government or international law(s). You shall not upload, post, email, transmit, or otherwise make available any unsolicited or unauthorized advertising, promotional materials, junk mail, spam mail, chain letters, or any other form of solicitation; encumber or suffer to exist any lien or security interest on the subject matter of these Terms; or make any representation or warranty on behalf of Good Deed in any form or manner whatsoever.

You hereby agree and assure that while communicating on the Good Deed Platform, including but not limited to giving cooking instructions to the Restaurants, communicating with our support agents on chat support, or with the Delivery Partners through any medium, you shall not use abusive and derogatory language or post any objectionable information that is unlawful, threatening, defamatory, or obscene. In the event you use abusive language or post objectionable information, Good Deed reserves the right to suspend the chat support service and/or block your access and usage of the Good Deed Platform at any time with or without notice.

Any Content uploaded by you shall be subject to the relevant laws of India and the country of use and may be disabled or subject to investigation under applicable laws. Furthermore, if you are found to be in non-compliance with the laws and regulations, these terms, or the privacy policy of the Good Deed Platform, Good Deed shall have the right to immediately block your access and usage of the Good Deed Platform. Good Deed shall also have the right to remove any non-compliant content and/or comment forthwith, uploaded by you, and shall further have the right to take appropriate recourse to such remedies as would be available to it under various statutes.

###### Customer Feedback

If you share or send any ideas, suggestions, changes, or documents regarding Good Deed's existing business ("Feedback"), you agree that:

(i) your Feedback does not contain the confidential, secretive, or proprietary information of third parties;

(ii) Good Deed is under no obligation of confidentiality with respect to such Feedback and shall be free to use the Feedback on an unrestricted basis;

(iii) Good Deed may have already received similar Feedback from some other customer or it may be under consideration or in development; and

(iv) By providing the Feedback, you grant us a binding, non-exclusive, royalty-free, perpetual, global license to use, modify, develop, publish, distribute, and sublicense the Feedback. You also irrevocably waive, against Good Deed and its customers, any claims/assertions, whatsoever of any nature, with regard to such Feedback.

Please provide only specific Feedback on Good Deed's existing products or marketing strategies; do not include any ideas that Good Deed's policy will not permit it to accept or consider.

Notwithstanding the aforementioned clause, Good Deed or any of its employees do not accept or consider unsolicited ideas, including ideas for new advertising campaigns, new promotions, new or improved products or technologies, product enhancements, processes, materials, marketing plans, or new product names. Please do not submit any unsolicited ideas, original creative artwork, suggestions, or other works ("Submissions") in any form to Good Deed or any of its employees.

The purpose of this policy is to avoid potential misunderstandings or disputes when Good Deed's products or marketing strategies might seem similar to ideas submitted to Good Deed. If, despite our request to not send us your ideas, you still submit them, then regardless of what your letter says, the following terms shall apply to your Submissions.

###### Terms of Idea Submission

You agree that:

(1) your Submissions and their Contents will automatically become the property of Good Deed, without any compensation to you;

(2) Good Deed may use or redistribute the Submissions and their contents for any purpose and in any way;

(3) there is no obligation for Good Deed to review the Submission; and

(4) there is no obligation to keep any Submissions confidential.

###### Advertising

Some of the services are supported by advertising revenue and may display advertisements and promotions. These advertisements may be targeted to the content of information stored on the services, queries made through the services, or other information. The manner, mode, and extent of advertising by Good Deed on the services are subject to change without specific notice to you. In consideration for Good Deed granting you access to and use of the services, you agree that Good Deed may place such advertising on the services.

Part of the site may contain advertising information or promotional material or other material submitted to Good Deed by third parties or customers. Responsibility for ensuring that material submitted for inclusion on the Good Deed platform or mobile apps complies with applicable international and national law is exclusively on the party providing the information/material. Your correspondence or business dealings with, or participation in promotions of, advertisers other than Good Deed found on or through the Good Deed platform and/or mobile apps, including payment and delivery of related goods or services, and any other terms, conditions, warranties, or representations associated with such dealings, shall be solely between you and such advertiser. Good Deed will not be responsible or liable for any error or omission, inaccuracy in advertising material, or any loss or damage of any sort incurred as a result of any such dealings or as a result of the presence of such other advertiser(s) on the Good Deed platform and mobile application.

For any information related to a charitable campaign ("Charitable Campaign") sent to customers and/or displayed on the Good Deed platform where customers have an option to donate money by way of (a) payment on a third-party website or (b) depositing funds to a third-party bank account, Good Deed is not involved in any manner in the collection or utilization of funds collected pursuant to the Charitable Campaign. Good Deed does not accept any responsibility or liability for the accuracy, completeness, legality, or reliability of any information related to the Charitable Campaign. Information related to the Charitable Campaign is displayed for informational purposes only, and customers are advised to do independent verification before taking any action in this regard.

###### Additional Terms and Conditions for Customers using the various services offered by Good Deed:

**FOOD ORDERING AND DELIVERY:**

a. Good Deed provides food ordering and delivery services by entering into contractual arrangements with restaurant partners ("Restaurant Partners") and stores (as defined below) on a principal-to-principal basis for the purpose of listing their menu items or the products (as defined below) for food ordering and delivery by the customers on the Good Deed platform.

b. The customers can access the menu items or products listed on the Good Deed platform and place orders against the Restaurant Partner(s)/Store(s) through Good Deed.

c. Your request to order food and beverages or products from a Restaurant Partner or a Store page on the Good Deed platform shall constitute an unconditional and irrevocable authorization issued in favor of Good Deed to place orders for food and beverages or products against the Restaurant Partner(s)/Store(s) on your behalf.

d. Delivery of an order placed by you through the Good Deed platform may either be undertaken directly by the Restaurant Partner or the Store against whom you have placed an order or facilitated by Good Deed through a third party who may be available to provide delivery services to you ("Delivery Partners"). In both these cases, Good Deed is merely acting as an intermediary between you and the Delivery Partners or you and the Restaurant Partner or the Store, as the case may be.

e. The acceptance by a Delivery Partner of undertaking the delivery of your order shall constitute a contract of service under the Consumer Protection Act, 2019, or any successor legislation, between you and the Delivery Partner, to which Good Deed is not a party under any applicable law. It is clarified that Good Deed does not provide any delivery or logistics services and only enables the delivery of food and beverages or products ordered by the customers through the Good Deed platform by connecting the customers with the Delivery Partners or the Restaurant Partners or the Store, as the case may be.

f. Where Good Deed is facilitating the delivery of an order placed by you, Good Deed shall not be liable for any acts or omissions on the part of the Delivery Partner, including deficiency in service, wrong delivery of order, time taken to deliver the order, order package tampering, etc.

g. You may be charged a delivery fee for the delivery of your order by the Delivery Partner or the Restaurant Partner or the Store, as the Delivery Partner or the Restaurant Partner or the Store may determine ("Delivery Charges"). You agree that Good Deed is authorized to collect, on behalf of the Restaurant Partner or the Delivery Partner or the Store, the Delivery Charges for the delivery service provided by the Restaurant Partner or the Store or the Delivery Partner, as the case may be. The Delivery Charges may vary from order to order, which may be determined based on multiple factors that shall include, but not be limited to, Restaurant Partner/Store, order value, distance, time of the day. Good Deed will inform you of the Delivery Charges that may apply to you, provided you will be responsible for Delivery Charges incurred for your order regardless of your awareness of such Delivery Charges.

h. In addition to the Delivery Charges, you may also be charged an amount towards delivery surge for the delivery of your order facilitated by the Delivery Partner or the Restaurant Partner or the Store, which is determined based on various factors, including but not limited to, distance covered, time taken, demand for delivery, real-time analysis of traffic and weather conditions, seasonal peaks, or such other parameters as may be determined from time to time ("Delivery Surge"). You agree that Good Deed is authorized to collect, on behalf of the Restaurant Partner or the Delivery Partner or the Store, the Delivery Surge for the delivery service provided by the Restaurant Partner or the Store or the Delivery Partner, as the case may be. The Delivery Surge may vary from order to order, which may be determined based on multiple factors that shall include, but not be limited to, Restaurant Partner/Store, order value, distance, demand during peak hours. Good Deed will use reasonable efforts to inform you of the Delivery Surge that may apply to you, provided you will be responsible for the Delivery Surge incurred for your order regardless of your awareness of such Delivery Surge.

i. In respect of the order placed by you, Good Deed shall issue documents like order summary, tax invoices, etc., as per the applicable legal regulations and common business practices.

j. You are expected to respect the dignity and diversity of Delivery Partners, and accordingly, you agree not to discriminate against any Delivery Partner on the basis of discrimination characteristics (as defined below). You are also expected to enable the provision of a secure and fearless gig/platform work environment for the delivery partners, including prevention and deterrence of harassment (including sexual harassment) towards Delivery Partners.

Discrimination Characteristics shall mean discrimination based on race, community, religion, disability, gender, sexual orientation, gender identity, age (insofar as permitted by applicable laws to undertake the relevant gig work), genetic information, or any other legally protected status.

A. Food Ordering and Delivery with Restaurant Partners:

a. All prices listed on the Good Deed platform are provided by the Restaurant Partner, including packaging or handling charges, if any, at the time of publication on the Good Deed platform, and the same are displayed by Good Deed as received from the Restaurant Partner. While we take great care to keep them up to date, the final price charged to you by the Restaurant Partner, including the packaging and handling charges, may change at the time of delivery. In the event of a conflict between the price on the Good Deed platform and the price charged by the Restaurant Partner, the price charged by the Restaurant Partner shall be deemed to be the correct price, except for the Delivery Charge of Good Deed.

b. On-Time Guarantee: For Good Deed Gold members in India, orders at select Restaurant Partners may have this service auto-enabled. When enabled, Good Deed uses its technology platform to allocate a suitable delivery partner, who provides the service, in such a way that it minimizes the delays in the orders. This includes prioritizing the allocation of Delivery Partners, along with making sure these orders are not clubbed with any other orders. However, you acknowledge that such services are facilitated by the Delivery Partner on a best-effort basis; hence, should your order fail to reach you on or prior to the On-Time Guarantee Time, you would be eligible to claim and receive a coupon of up to 100% of your order value. You will be required to claim the Coupon within twenty-four (24) hours from the time such order is delivered to you, failing which your eligibility to receive the Coupon will expire. Furthermore, the validity period of the Coupon would be 3 (three) days from receipt thereof. Notwithstanding anything set out hereinabove, you shall not be eligible to receive the Coupon if:

i. Delay on the On-Time Guarantee Time is due to unforeseen reasons, e.g., strikes, natural disasters, or the Restaurant Partner's inability to provide the order.

ii. You change, edit, modify or cancel such order or do any act that has the effect of changing, editing, or modifying such order, including but not limited to adding or changing the items ordered, receiving delivery at a location other than the one indicated at the time of placing the order, etc.

iii. You indulge in actions intended to delay the order, including but not limited to asking the Delivery Partner to delay the order, becoming unresponsive on call, etc.

iv. The order is a bulk order (as per Good Deed's standard order size).

v. The order is canceled due to any reason.

For the purpose of this clause, words capitalized shall have the following meaning: "On-Time Guarantee Time" shall mean the Promised Delivery Time, which starts when the order is accepted by the Restaurant Partner. The actual delivery time will be counted as the period between the Restaurant Partner accepting the order and the Delivery Partner reaching within a 100-meter radius from your location or the first barrier point (security guard/reception, etc.), whichever is earlier. "Coupon" shall mean a one-time code generated by Good Deed for the delay in the On-Time Guarantee Time to be used as per applicable conditions.

c. Good Deed Pro for Food Ordering and Delivery: Good Deed Pro members in India can avail Discounts (as defined below) extended by Partner Restaurants (as defined below) on home delivery. Please refer to the terms and conditions set out below in clause 3.

d. Good Deed Gift:

i. You may place an order with a Restaurant Partner to be delivered to someone else, your loved ones (may or may not be a Good Deed customer) ("Gift Recipient") as a gift ("Gift Order").

ii. To place a Gift Order, you will be required to provide the Gift Recipient's contact details, such as name, phone number, address, or any other information that may be reasonably required ("Contact Information"), to enable the Restaurant Partner and Delivery Partner to deliver the Gift Order.

iii. By availing the Good Deed Gift feature, you warrant and represent that you have obtained the Gift Recipient's consent to provide Good Deed with the Contact Information. You hereby further warrant and represent to indemnify and hold Good Deed, its directors, employees, affiliates, and subsidiaries, and their respective directors, employees, harmless against any claims or disputes initiated by the Gift Recipient whose Contact Information was provided by you for the purpose of placing the Gift Order.

iv. By placing a Gift Order, you hereby irrevocably undertake to be responsible for any refusal made by the Gift Recipient or for any prejudice suffered by the latter or Good Deed.

v. In case the Gift Recipient is non-contactable, the Restaurant Partner, Delivery Partner, or we may contact you for further assistance.

vi. You may send a message with the Gift order which we will endeavor to deliver; however, sometimes the message can't be sent.

vii. You will not be charged any additional payment for the Gift Order. All charges will be in a similar manner as for a regularly placed online order.

viii. In the event, the Gift Recipient wishes to raise any issue regarding the Gift Order, they can do so by requesting you to do it or via the chat support on the Good Deed platform or write to us at [order@gooddeed.in](mailto:order@gooddeed.in).

ix. Any refund on the Gift order shall be provided to the sender, and the Gift Recipient shall not receive any benefit.

x. You explicitly and unambiguously consent to the collection, use, and transfer, in electronic or other forms, of personal information for the purposes of Good Deed Gift. You will be required to share certain personal information with Good Deed, including your name, phone number, email address, payment details, and Good Deed will use these details in accordance with the Privacy Policy published on [gooddeed.in](https://gooddeed.in)/privacy.

xi. All other terms and conditions for food ordering and delivery services provided herein under clause XIII[1] shall apply as is.

##### General Terms and Conditions

a. Good Deed is not a manufacturer, seller, or distributor of food and beverages or products and merely places an order against the Restaurant Partner(s)/Store(s) on behalf of the Customers pursuant to the unconditional and irrevocable authority granted by the Customers to Good Deed. Good Deed facilitates the sale and purchase of food and beverages or products between Customers and Restaurant Partners/Store(s) under the contract for the sale and purchase of food and beverages or products between the Customers and Restaurant Partners/Store(s).

b. Good Deed shall not be liable for any acts or omissions on the part of the Restaurant Partner/Store(s), including deficiency in service, wrong delivery of order/order mismatch, quality, incorrect pricing, deficient quantity, time taken to prepare or deliver the order, etc.

c. The Restaurant Partner(s)/Store(s) shall be solely responsible for any warranty/guarantee of the food and beverages or products sold to the Customer, and in no event shall it be the responsibility of Good Deed.

d. For the Customers in India, it is hereby clarified by Good Deed that the liability of any violation of the applicable rules and regulations made thereunder shall solely rest with the sellers/brand owners, vendors, Restaurant Partner(s)/Store(s), importers, or manufacturers of the food products, products, or any pre-packaged goods accordingly. For the purpose of clarity, pre-packaged goods shall mean the food and beverages items which are placed in a package of any nature, in such a manner that the contents cannot be changed without tampering, and which are ready for sale to the customer or as may be defined under the Food Safety and Standards Act, 2006 from time to time.

e. Please note that some of the food and beverages or products may be suitable for certain ages only. You should check the dish you are ordering and read its description, if provided, prior to placing your order. Good Deed shall not be liable in the event the food and beverages or the product ordered by you do not meet your dietary or any other requirements and/or restrictions.

f. While placing an order, you shall be required to provide certain details, including, without limitation, contact number and delivery address. You agree to take particular care when providing these details and warrant that these details are accurate and complete at the time of placing an order. By providing these details, you express your acceptance of Good Deed's terms and privacy policies.

g. You or any person instructed by you shall not resell food and beverages or products purchased via the Good Deed platform.

h. The total price for food ordered, including the delivery charges and other charges, will be displayed on the Good Deed platform when you place your order, which may be rounded up to the nearest amount. Customers shall make full payment towards such food or products ordered via the Good Deed platform.

i. Any amount that may be charged to you by Good Deed over and above the order value shall be inclusive of applicable taxes.

j. Delivery periods/Takeaway time quoted at the time of ordering are approximate only and may vary.

k. Personal promo code can only be used by you subject to such terms and conditions set forth by Good Deed from time to time.

#####  Cancellation and refund policy:

i. You acknowledge that 
- your cancellation or attempted or purported cancellation of an order or 
- cancellation due to reasons not attributable to Good Deed, that is, in the event you provide incorrect particulars, contact number, delivery address, etc., or that you were unresponsive, not reachable, or unavailable for fulfillment of the services offered to you, shall amount to a breach of your unconditional and irrevocable authorization in favor of Good Deed to place that order against the Restaurant Partners/Store(s) on your behalf ("Authorization Breach"). In the event you commit an Authorization Breach, you shall be liable to pay the liquidated damages of an amount equivalent to the order value. You hereby authorize Good Deed to deduct or collect the amount payable as liquidated damages through such means as Good Deed may determine in its discretion, including, without limitation, by deducting such amount from any payment made towards your next order.

ii. There may be cases where Good Deed is either unable to accept your order or cancels the order due to reasons including, without limitation, technical errors, unavailability of the item(s) ordered, or any other reason attributable to Good Deed, Restaurant Partner/Store, or Delivery Partner. In such cases, Good Deed shall not charge a cancellation charge from you. If the order is canceled after payment has been charged and you are eligible for a refund of the order value or any part thereof, the said amount will be reversed to you.

iii. No replacement/refund/or any other resolution will be provided without the Restaurant Partner's/Store(s)' permission.

iv. Any complaint, with respect to the order, which shall include instances but not be limited to food spillage, foreign objects in food, delivery of the wrong order or food and beverages or products, poor quality, you will be required to share the proof of the same before any resolution can be provided.

v. You shall not be entitled to a refund in case instructions placed along with the order are not followed in the form and manner you had intended. Instructions are followed by the Restaurant Partner/Store on a best-efforts basis.

vi. All refunds shall be processed in the same manner as they are received, unless refunds have been provided to you in the form of credits. The refund amount will reflect in your account based on respective banks' policies.

##### Good Deed Pay:

For Good Deed Pay users in India:

In the event a customer makes a payment for the bill amount (as defined below) using Good Deed Pay on the Good Deed Platform in the city(ies) where Good Deed Pay is available, the following terms and conditions shall be specifically applicable to the customers:

a. The customer can make a payment for the bill amount on the Good Deed Platform by using any payment method available in the 'Payment' section on the Good Deed Platform.

For the purposes of Good Deed Pay, the "bill amount" shall mean the total amount (including applicable taxes, service charge, and other charges, as may be applicable, excluding tip) set out in the dining bill for food and beverages availed by a customer at the restaurant partnered with Good Deed for Good Deed Pay.

b. The customer acknowledges that upon fulfillment of payment of the bill amount via the Good Deed Platform, the customer will be required to show the payment confirmation to the restaurant partnered with Good Deed for Good Deed Pay.

c. Upon making a payment for the bill amount using Good Deed Pay via the Good Deed Platform, the customer will be entitled to Good Deed Pay Benefits (as defined below), subject to successful payment being made by the customer.

For the purposes of Good Deed Pay, "Good Deed Pay Benefits" shall include but not be limited to:

a. Instant discount(s) applicable on the bill amount.

b. Additional banking partner offer(s) applicable on the final payable amount, net of other discounts (excluding tips), provided that the final payable amount meets the minimum and maximum order value criteria for a particular offer, if any, as may be decided by the bank from time to time.

c. Scratch card offer(s) with cashback in the form of Good Deed Credits, provided the final payable amount is a minimum of INR 50.

d. The customers can make a payment for the bill amount using Good Deed Pay by either scanning the Good Deed QR code at the restaurant partnered with Good Deed for Good Deed Pay or by searching for the restaurant partnered with Good Deed for Good Deed Pay on the Good Deed Platform and selecting Good Deed Pay as the payment method.

e. The customer will be solely responsible to pay the restaurant partnered with Good Deed for Good Deed Pay the bill amount along with all costs and charges payable for all the other items for which they have placed an order and which are not covered under the bill amount. In the event of a concern raised regarding the payment using Good Deed Pay, we shall use our best endeavors to assist you; however, such payment will be subject to verification and confirmation from the restaurant partnered with Good Deed for Good Deed Pay.

f. The customer acknowledges that in order to receive Good Deed Credits, the customer shall scratch the scratch card offer. Once the scratch card offer is revealed to the customer, the customer shall avail the Good Deed Credits before the date of expiry of such credits. For the purpose of clarity, the date of expiry of Good Deed Credits will be available on each scratch card offer.

g. Your access to Good Deed Pay Benefits shall be subject to receipt of successful payments by Good Deed.

h. The customer is required to be present at the partner restaurant when using Good Deed Pay.

i. The customer acknowledges that the Good Deed Pay Benefits cannot be clubbed with any ongoing offers by the partner restaurant at the restaurant premise or on items which are being sold at the maximum retail price (MRP).

j. The customer cannot use Good Deed Pay for dine-in if the customer is employed at the same partner restaurant.

k. Good Deed reserves the right to terminate/suspend Good Deed Pay Benefits to the customer if Good Deed determines in its sole discretion that:

(i) The customer has violated the terms of Good Deed Pay set out herein.

(ii) The customer has been involved in activities that are in contravention of the Good Deed Pay terms and/or any terms for the usage of the Good Deed Platform.

(iii) The customer has engaged in activities which are fraudulent/unlawful in nature while availing any of the services of Good Deed.

l. Good Deed reserves the right to block and/or terminate/suspend Good Deed Pay Benefits on account of a breach of these terms, including any fraudulent and suspicious activity while using Good Deed Pay.

m. Good Deed Pay and the associated Good Deed Pay Benefits will be applicable to the customers on all days. Provided, however, the benefit of instant discount(s) applicable on the bill value shall not be applicable for the customer on Exclusion Days (as set out below).

n. The instant discounts under Good Deed Pay Benefits shall be as follows:

a. If a Good Deed Pro member uses Good Deed Pay via the Good Deed Platform at a restaurant partnered with Good Deed Pro, the discount will be reflected to the customer as 'Good Deed Pro discount' (the Good Deed Pro Discount will be applicable along with the Good Deed Pay Benefits on the bill amount).

b. If a Good Deed Pro member uses Good Deed Pay via the Good Deed Platform at a restaurant not partnered with Good Deed Pro, the discount will be reflected as 'instant discount'.

c. If a non-Good Deed Pro customer uses Good Deed Pay via the Good Deed Platform at a restaurant partnered with Good Deed Pro, the discount will be reflected as 'instant discount', only if applicable.

d. If a non-Good Deed Pro customer uses Good Deed Pay via the Good Deed Platform at a restaurant not partnered with Good Deed Pro, the discount will be reflected as 'instant discount'.

o. The customer acknowledges that the Edition Wallet and Good Deed Pay are two different payment methods available on the Good Deed Platform and the customer shall be entitled to Good Deed Pay Benefits only if the payment for the bill amount is made using Good Deed Pay via the Good Deed Platform.

p. The customer acknowledges that Good Deed Pay is being made available purely on a 'best effort' basis and availing Good Deed Pay is voluntary.

q. Good Deed reserves the right to modify the Good Deed Pay Benefits and/or these Good Deed Pay Terms from time to time or at any time, modify or discontinue, temporarily or permanently, Good Deed Pay Benefits and/or these Good Deed Pay Terms, with or without prior notice, and the decision of Good Deed shall be final and binding in this regard.

r. These Good Deed Pay Terms do not alter in any way the terms or conditions of any other program or arrangement the customer may have with Good Deed. Termination of Good Deed Pay and these Good Deed Pay Terms shall have no effect on the Terms of Service governing the contractual relationship between the customers and Good Deed.

s. For any help or queries, you may reach out to us via chat support or write to us at [dining@gooddeed.in](mailto:dining@gooddeed.in).

##### Book Service/Table Reservations:

a. The customer can make a request for booking a table at a restaurant, offering table reservations via the Good Deed platform and related mobile or software application, and such booking will be confirmed to a customer by email, short message service ("SMS"), and/or by any other means of communication only after the restaurant accepts and confirms the booking. The availability of a booking is determined at the time a customer requests a table reservation. While using the Good Deed Book Service, you shall be required to provide certain details. You agree to provide correct details and warrant that these details are accurate and complete. By submitting a booking request, you express your acceptance of Good Deed's terms and privacy policies and agree to receive booking confirmations by email, SMS, and/or by any other means of communication after booking a table through the Good Deed Book Service. The customer further agrees not to make more than one reservation for the customer's personal use for the same mealtime.

b. Fees: Good Deed may charge a booking fee ("Booking Fee") from the customer upon availing the Good Deed Book Service. This Booking Fee shall be adjusted by the restaurant against the total bill for the items consumed by the customer at such a restaurant. Any balance amount remaining to be paid after the deduction of the Booking Fee from the restaurant bill shall be payable by the customer. The customer shall also be liable to pay any additional charges and/or applicable taxes that may be applicable to the transaction. In the event of any change in the amount of the Booking Fee after the payment is made by the customer, the amount of the Booking Fee already paid by the customer will be applicable. The customer may be required to furnish the payment instrument at the restaurant from which payment has been made for identification purposes.

c. Modifications & Cancellations: Any request for modification of the confirmed booking will be subject to acceptance by the restaurant. Good Deed will use its best endeavors to keep the customer informed of the status of the booking. For bookings where the Booking Fee is not applicable, the customer may cancel such a booking thirty (30) minutes in advance of the scheduled booking time. A confirmed booking for which the Booking Fee has been charged from a customer, the modification option will not be available. However, the customer is required to cancel the confirmed booking twenty-four (24) hours prior to the scheduled booking time to avail the refund. Unless otherwise provided herein these Terms, Good Deed shall refund the Booking Fee to the customer within seven (7) working days from the date of such cancellation. However, Good Deed reserves the right to retain the Booking Fee in the event the customer fails to cancel the booking within the estimated timeframe mentioned herein above.

d. Late Arrivals: Good Deed advises the customer to arrive 10 minutes in advance of the scheduled booking time. The restaurant reserves the right to cancel your booking and allocate the table to other guests in case of late arrivals, and Good Deed shall in no manner be liable for such cancellation initiated by the restaurant. Good Deed hereby reserves its right to retain the Booking Fee paid by the customers, in the event the customer is late by more than 10 minutes from the scheduled booking time and/or fails to show up at the restaurant.

e. Dispute: In the event the restaurant fails to honor the confirmed booking or in case of any other complaint or dispute raised by the customer in relation to the booking, the customer shall raise such disputes with Good Deed within 30 minutes from the scheduled booking time at the helpline numbers provided herein below. Upon receipt of such complaint or dispute, Good Deed will make reasonable efforts to verify the facts of such complaint/dispute with the restaurant and may, at its sole discretion, initiate the refund of the Booking Fee to such customer.

f. Personal Information: Customers will be required to share certain personal information with Good Deed and/or the restaurant, including but not limited to their name, phone number, email address, in order to avail the Good Deed Book Service. The customer hereby permits Good Deed to share such personal information with the restaurant to confirm such customer's booking and/or such other communication relating to, but not limited to, the Good Deed Book Service or any promotions by the restaurant. Good Deed will use these details in accordance with the Privacy Policy published here. Good Deed will share your personal information with the restaurant for the purpose of your reservation. However, notwithstanding anything otherwise set out herein, Good Deed shall in no manner be liable for any use of your personal information by such a restaurant for any purpose whatsoever.

g. Additional Request: In the case of any additional request communicated by the customer at the time of booking, the same will be conveyed to the restaurant by Good Deed and confirmed to the customer based on the restaurant's response. While Good Deed will take all the care to ensure timely communication of these requests to both the customer and the restaurant, the liability to fulfill the request lies solely with the restaurant, and Good Deed shall in no manner be liable if the restaurant does not honor any of the confirmed additional requests of the customers.

h. Call Recording: Good Deed may contact via telephone, SMS, or other electronic messaging or by email with information about your Good Deed Book Service or any feedback thereon. Any calls that may be made by Good Deed, by itself or through a third party, to the customers or the restaurant pertaining to any booking requests of a customer may be recorded for internal training and quality purposes by Good Deed or any third party appointed by Good Deed.

i. Liability Limitation: Notwithstanding anything otherwise set out herein, Good Deed shall in no manner be liable in any way for any in-person interactions with the restaurant as a result of the booking or for the customer's experience at the restaurant or in the event a restaurant does not honor a confirmed booking. Good Deed is only a platform connecting customers to the restaurant and shall not be liable for any acts or omissions on the part of the restaurant, including deficiency in service, quality of food, time taken to serve, or any other experience of the customer.

j. Contact Us: You may write to us at [bookings@gooddeed.in](mailto:bookings@gooddeed.in) for any further queries with regard to the Good Deed Book Service and may also contact us on the following numbers for more information.

- Personal Information: Good Deed members will be required to share certain personal information with Good Deed, including their name, phone number, email address, payment details, to register on Good Deed. Good Deed will use these details in accordance with the Privacy Policy published on [gooddeed.in](https://gooddeed.in)/privacy.

Assignment: Good Deed may assign or transfer any of its rights or obligations under these Terms and conditions to any of its affiliates or any third party at any time.

###### FOOD HYGIENE RATINGS:

a. The Food Hygiene Ratings ("Hygiene Ratings") is an initiative of Good Deed in partnership with certified auditors ("Hygiene Auditors") to audit restaurants. The customer acknowledges that Good Deed is merely acting as a facilitator in the hygiene audit process and does not conduct any hygiene audit by itself.

b. The customer understands and agrees that the Hygiene Ratings displayed on the Good Deed Platform are for informational purposes only and merely indicate the hygiene standards of a restaurant at the time such audit is conducted by the Hygiene Auditors. The Hygiene Ratings shall not be deemed to be an indicator of the food quality standards maintained by a restaurant.

C. Validity:

- The validity of the hygiene rating(s) displayed on the Good Deed Platform shall be for a period of six (6) or twelve (12) months, as the case may be, from the date of the last audit as displayed on the Good Deed Platform.

- Good Deed reserves the right to remove the hygiene rating(s) for a restaurant upon expiry of the validity of the hygiene rating(s), without any prior intimation to the customer.

D. Disclaimer and Liability:

- The hygiene rating(s) that are displayed on the Good Deed Platform are on an "as available" basis, based on the data provided to Good Deed by the hygiene auditor(s) for a restaurant, and Good Deed disclaims all warranties with respect to the hygiene rating(s) or any information displayed in this regard on the Good Deed Platform.

- Any actions taken by a customer relying upon the hygiene rating(s) or any information displayed in this regard on the Good Deed Platform shall be strictly at such customer's own risk, and Good Deed shall in no manner be held liable for any losses or damages that may arise in connection with the use of this information or any inaccuracy, invalidity, or discrepancy in the hygiene rating(s). Good Deed expressly disclaims all liabilities that may arise in connection to the reliance by a customer on such hygiene rating(s), including without limitation, any consumption of food or any other items served at a restaurant, or any other services that may be provided by a restaurant.

- Good Deed shall under no circumstances be held liable if a restaurant does not display the correct and accurate hygiene rating(s) on its restaurant premises, website, or any other platform.

E. The customer acknowledges that the hygiene rating(s) as displayed on the Good Deed Platform shall under no circumstances be construed to be proof of the hygiene standards or practices that are being adopted by the restaurant, and such hygiene rating(s) shall not be used as evidence in a court of law or governmental authority or disputed in any manner whatsoever. The customer further understands that the restaurant is solely responsible to maintain the hygiene and food safety standards in compliance with the applicable laws.

F. Contact Us: You may contact us at [hygiene@gooddeed.in](mailto:hygiene@gooddeed.in) for any further queries regarding hygiene ratings.

#### Disclaimer of Warranties, Limitation of Liability, and Indemnification

##### Disclaimer of Warranties

You acknowledge and agree that the services are provided "as is" and "as available" and that your use of the services shall be at your sole risk. To the fullest extent permitted by applicable law, Good Deed, its affiliates, and their respective officers, directors, employees, agents, affiliates, branches, subsidiaries, and licensors ("Good Deed Parties") disclaim all warranties, express or implied, in connection with the services including mobile apps and your use of them. To the fullest extent permitted by applicable law, the Good Deed Parties make no warranties or representations that the services have been and will be provided with due skill, care, and diligence or about the accuracy or completeness of the services' content and assume no responsibility for any: 
- errors, mistakes, or inaccuracies of content; 
-  personal injury or property damage, of any nature whatsoever, resulting from your access to and use of the services; 
-  any unauthorized access to or use of our servers and/or any and all personal information stored therein; 
-  any interruption or cessation of transmission to or from the services; 
- any bugs, viruses, trojan horses, or the like which may be transmitted to or through the services through the actions of any third party; 
-  any loss of your data or content from the services and/or; 
-  any errors or omissions in any content or for any loss or damage of any kind incurred as a result of the use of any content posted, emailed, transmitted, or otherwise made available via the services. Any material downloaded or otherwise obtained through the use of the services is done at your own discretion and risk, and you will be solely responsible for any damage to your computer system or other device or loss of data that results from the download of any such material. 

The Good Deed Parties will not be a party to or in any way be responsible for monitoring any transaction between you and third-party providers of products or services. You are solely responsible for all of your communications and interactions with other customers of the services and with other persons with whom you communicate or interact as a result of your use of the services. 

No advice or information, whether oral or written, obtained by you from Good Deed or through or from the services, shall create any warranty not expressly stated in the terms. Unless you have been expressly authorized to do so in writing by Good Deed, you agree that in using the services, you will not use any trademark, service mark, trade name, logo of any company or organization in a way that is likely or intended to cause confusion about the owner or authorized user of such marks, names, or logos.

##### Limitation of Liability

To the fullest extent permitted by applicable law, in no event shall the Good Deed Parties be liable to you for any damages resulting from any: 
- errors, mistakes, or inaccuracies of content; and/or 
-  personal injury or property damage, of any nature whatsoever, resulting from your access to and use of the services, including mobile app, and/or 
- any unauthorized access to or use of our servers and/or any and all personal information stored therein, and/or 
- any interruption or cessation of transmission to or from our servers, and/or 
- any bugs, viruses, trojan horses, or the like, which may be transmitted to or through the services by any third party, and/or (vi) any loss of your data or content from the services, and/or 
- any errors or omissions in any content or for any loss or damage of any kind incurred as a result of your use of any content posted, transmitted, or otherwise made available via the services, whether based on warranty, contract, tort, or any other legal theory, and whether or not the Good Deed Parties are advised of the possibility of such damages, and/or 
- the disclosure of information pursuant to these terms or our privacy policy, and/or 
- your failure to keep your password or account details secure and confidential, and/or 
- loss or damage which may be incurred by you, including but not limited to loss or damage as a result of reliance placed by you on the completeness, accuracy, or existence of any advertising, or as a result of any relationship or transaction between you and any advertiser or sponsor whose advertising appears on the services, and/or delay or failure in performance resulting from causes beyond Good Deed's reasonable control. In no event shall the Good Deed Parties be liable to you for any indirect, incidental, special, punitive, exemplary, or consequential damages whatsoever, however caused and under any theory of liability, including but not limited to, any loss of profit (whether incurred directly or indirectly), any loss of goodwill or business reputation, any loss of data suffered, cost of procurement of substitute goods or services, or other intangible loss.

##### Indemnification

You agree to indemnify, defend, and hold harmless the Good Deed Parties from and against any third-party claims, damages (actual and/or consequential), actions, proceedings, demands, losses, liabilities, costs, and expenses (including reasonable legal fees) suffered or reasonably incurred by us arising as a result of, or in connection with: 
- Your Content; 
- your unauthorized use of the Services, or products or services included or advertised in the Services; 
- your access to and use of the Services; 
- your violation of any rights of another party; or 
- your breach of these Terms, including, but not limited to, any infringement by you of the copyright or intellectual property rights of any third party. We retain the exclusive right to settle, compromise and pay, without your prior consent, any and all claims or causes of action which are brought against us. We reserve the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify us, and you agree to cooperate with our defense of these claims. You agree not to settle any matter in which we are named as a defendant and/or for which you have indemnity obligations without our prior written consent. We will use reasonable efforts to notify you of any such claim, action, or proceeding upon becoming aware of it.

###### Termination of Your Access to the Services

You can delete your account at any time by contacting us via the "Contact Us" link at the bottom of every page or by following this process: Go to Profile => Setting  Security =>   click on the 'Delete Account'  => button and cease further use of the Services.

We may terminate your use of the Services and deny you access to the Services in our sole discretion for any reason or no reason, including your: 
- violation of these Terms; or 
- lack of use of the Services. You agree that any termination of your access to the Services may be affected without prior notice, and acknowledge and agree that we may immediately deactivate or delete your account and all related information and/or bar any further access to your account or the Services. If you use the Services in violation of these Terms, we may, in our sole discretion, retain all data collected from your use of the Services. Furthermore, you agree that we shall not be liable to you or any third party for the discontinuation or termination of your access to the Services.

###### General Terms Interpretation:

The section and subject headings in these terms are included for reference only and shall not be used to interpret any provisions of these terms.

**Entire Agreement and Waiver:**

The terms, together with the "Privacy Policy" and "Guidelines and Policies," shall constitute the entire agreement between you and us concerning the services. No failure or delay by us in exercising any right, power, or privilege under the terms shall operate as a waiver of such right or acceptance of any variation of the terms, and nor shall any single or partial exercise by either party of any right, power, or privilege preclude any further exercise of that right or the exercise of any other right, power, or privilege.

**Severability:**

If any provision of these terms is deemed unlawful, invalid, or unenforceable by a judicial court for any reason, then that provision shall be deemed severed from these terms, and the remainder of the terms shall continue in full force and effect.

**Partnership or Agency:**

None of the provisions of these terms shall be deemed to constitute a partnership or agency between you and Good Deed, and you shall have no authority to bind Good Deed in any form or manner whatsoever.

**Governing Law/Waiver:**

(a) For customers residing in India: These terms shall be governed by the laws of India. The courts of New Delhi shall have exclusive jurisdiction over any dispute arising under these terms.

For all customers: YOU MUST COMMENCE ANY LEGAL ACTION AGAINST US WITHIN ONE (1) YEAR AFTER THE ALLEGED HARM INITIALLY OCCURS. FAILURE TO COMMENCE THE ACTION WITHIN THAT PERIOD SHALL FOREVER BAR ANY CLAIMS OR CAUSES OF ACTION REGARDING THE SAME FACTS OR OCCURRENCE, NOTWITHSTANDING ANY STATUTE OF LIMITATIONS OR OTHER LAW TO THE CONTRARY. WITHIN THIS PERIOD, ANY FAILURE BY US TO ENFORCE OR EXERCISE ANY PROVISION OF THESE TERMS OR ANY RELATED RIGHT SHALL NOT CONSTITUTE A WAIVER OF THAT RIGHT OR PROVISION.

**Carrier Rates may Apply:**

By accessing the services through a mobile or other device, you may be subject to charges by your internet or mobile service provider, so check with them first if you are not sure, as you will be solely responsible for any such costs incurred.

**Linking and Framing:**

You may not frame the services. You may link to the services, provided that you acknowledge and agree that you will not link the services to any website containing any inappropriate, profane, defamatory, infringing, obscene, indecent, or unlawful topic, name, material, or information, or that violates any intellectual property, proprietary, privacy, or publicity rights. Any violation of this provision may, in our sole discretion, result in termination of your use of and access to the services effective immediately.

###### Notice of Copyright Infringement

Good Deed shall not be liable for any infringement of copyright arising out of materials posted on or transmitted through the Good Deed platform or items advertised on the Good Deed platform by end users or any other third parties. We respect the intellectual property rights of others and require those who use the services to do the same. We may, in appropriate circumstances and at our discretion, remove or disable access to material on the services that infringes upon the copyright rights of others. We also may, in our discretion, remove or disable links or references to an online location that contains infringing material or infringing activity. In the event that any customers of the services repeatedly infringe on others' copyrights, we may, in our sole discretion, terminate those individuals' rights to use the services. If you believe that your copyright has been or is being infringed upon by material found in the services, you are required to follow the below procedure to file a notification:

i. Identify in writing the copyrighted material that you claim has been infringed upon.

ii. Identify in writing the material on the services that you allege is infringing upon copyrighted material and provide sufficient information that reasonably identifies the location of the alleged infringing material (for example, the username of the alleged infringer and the business listing it is posted under).

iii. Include the following statement: "I have a good faith belief that the use of the content on the services as described above is not authorized by the copyright owner, its agent, or law."

iv. Include the following statement: "I swear under penalty of perjury that the information in my notice is accurate and I am the copyright owner or I am authorized to act on the copyright owner's behalf."

v. Provide your contact information, including your address, telephone number, and email address (if available).

vi. Provide your physical or electronic signature.

vii. Send us a written communication to [legal@gooddeed.in](mailto:legal@gooddeed.in).

You may be subject to liability if you knowingly make any misrepresentations on a takedown notice.

###### Grievance Redressal Mechanism:

**Customer Care Channels**

For any order-related issue, you may first reach out to us via chat support on the Good Deed app for real-time basis resolution.

You may write to us at [order@gooddeed.in](mailto:order@gooddeed.in), and we will strive to resolve your order-related grievance within the timelines prescribed under applicable laws.

For a complaint raised on [order@gooddeed.in](mailto:order@gooddeed.in), you will receive a unique ticket number to track the status of your complaint.

In case you do not receive a satisfactory response from the chat support and [order@gooddeed](mailto:and order@gooddeed).in as the case may be, you can escalate the matter to our Grievance Officer by giving a reference to the ticket number generated from [order@gooddeed.in](mailto:order@gooddeed.in), and we shall address your concern within an estimated time of forty-eight (48) hours.

For customers residing in India, please note, in compliance with The Information Technology Act, 2000, and the rules made thereunder, as well as The Consumer Protection Act 2019, and the rules made thereunder, the grievance redressal mechanism, including the contact details of the Grievance Officer and Nodal Officer, are given herein above.

Please note: Good Deed does not solicit confidential information such as OTP/CVV/PIN NUMBER/Card number either through call or mail or any other means. Please do not reveal these details to fraudsters and imposters claiming to be calling on Good Deed's behalf. You may report such suspicious activities to [order@gooddeed.in](mailto:order@gooddeed.in).`
export default function PrivacyPolicy() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <div className="blade-top-padding-lg blade-bottom-padding-lg mb-4 ">
        <h1 className="font-extrabold text-center text-green blade-top-padding-lg blade-top-margin-lg leading-relaxed md:block hidden">
          Terms & Conditions
        </h1>
        <h3 className="font-bold text-center  px-5  sm:w-10/12 lg:w-full mx-auto blade-top-padding-lg blade-top-margin-lg leading-relaxed md:hidden block">
          Terms & Conditions
        </h3>
      </div>
      <div className="markdown-wrapper md:w-11/12 sm:px-5 px-3 max-w-screen-lg mx-auto blade-bottom-padding-lg blade-bottom-margin-lg">
        <RenderMarkdown content={content} />
      </div>
    </>
  )
}
