import React, { Fragment, useState } from 'react'
import { z } from 'zod'
import { SubmitHandler, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Listbox, Transition } from '@headlessui/react'
import { MasterBtn } from 'atoms/buttons'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Slide from 'molecules/slide'
import { submitForm } from '../api/api'

// purpose field to be defined in the zod object schema in later build.
// headless ui does not trigger a change function for zod object schema

const purposes = [
  {
    title: 'Become a partner.',
    id: 'become_a_partner',
  },
  {
    title: 'Join the team.',
    id: 'join_the_team',
  },
]

type FormProps = {
  isPurposeField: boolean
  formTitle?: string
}

const formSchema = z.object({
  name: z
    .string()
    .min(1, 'Name is required.')
    .max(30, 'Name can not be more than 30 characters.'),
  email: z
    .string()
    .email('Invalid email address.')
    .min(1, 'Email is required.'),
  phone: z.string().length(10, 'Phone number should be of 10 digits.'),
  message: z.string(),
})

export type FormFieldSchema = z.infer<typeof formSchema>

export default function Form({ isPurposeField, formTitle = '' }: FormProps) {
  const [purpose, setPurpose] = useState(purposes[0])
  const [isTouched, setTouched] = useState(false) // avoid error while mounting the component
  const [isLoading, setLoading] = useState(false)
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormFieldSchema>({
    resolver: zodResolver(formSchema),
  })

  const onSubmit: SubmitHandler<FormFieldSchema> = async (data) => {
    const purposeValue = isPurposeField ? purpose.title : 'Business form'
    const formData: FormFieldSchema & { purpose: string } = Object.assign(
      data,
      { purpose: purposeValue },
    )
    setLoading(true)
    const res = await submitForm(formData)
    if (res.isDone) {
      toast.success('Form has been submitted successfully.')
    } else {
      toast.error('Failed to submit the form. Please try again later.')
    }
    reset()
    setLoading(false)
  }

  return (
    <>
      <ToastContainer />
      <section className="blade-bottom-padding-sm">
        <section className="blade-top-padding blade-bottom-padding-lg">
          <div>
            {formTitle && (
              <div className="pb-5 lg:pb-7">
                <Slide>
                  <h2
                    className="font-bold mx-auto text-center leading-normal blade-bottom-padding-sm lg:block hidden"
                    dangerouslySetInnerHTML={{ __html: formTitle }}
                  />
                  <h3
                    className="font-bold mx-auto text-center leading-normal blade-bottom-padding-sm lg:hidden block max-w-sm w-11/12  capitalize"
                    dangerouslySetInnerHTML={{ __html: formTitle }}
                  />
                </Slide>
              </div>
            )}
            <div>
              <Slide distance="20%">
                <form
                  noValidate
                  onSubmit={handleSubmit(onSubmit)}
                  className="max-w-screen-sm mx-auto px-3 md:w-11/12 grid  gap-4 md:gap-7"
                >
                  <div>
                    <label
                      htmlFor="name"
                      className="grid gap-1 text-base md:text-lg"
                    >
                      Name
                      <input
                        className=" py-2 md:py-3 px-2  placeholder:font-light placeholder:opacity-60  rounded-md  md:px-3 border border-solid  bg-white font-normal text-base"
                        id="name"
                        placeholder="Hey there! Let's break the ice, shall we?"
                        {...register('name')}
                      />
                      {errors.email && (
                        <span className="text-red-800 text-sm font-medium block mt-1">
                          {errors.name?.message}
                        </span>
                      )}
                    </label>
                  </div>

                  <div>
                    <label
                      htmlFor="email"
                      className="grid gap-1 text-base md:text-lg"
                    >
                      Email Address
                      <input
                        className=" py-2 md:py-3 px-2  placeholder:font-light placeholder:opacity-60  rounded-md  md:px-3 border border-solid  bg-white font-normal text-base"
                        id="email"
                        type="email"
                        inputMode="email"
                        placeholder="Make sure it's the one you check often."
                        {...register('email')}
                      />
                      {errors.email && (
                        <span className="text-red-800 text-sm font-medium block mt-1">
                          {errors.email?.message}
                        </span>
                      )}
                    </label>
                  </div>
                  <div>
                    <label
                      htmlFor="phone"
                      className="grid gap-1 text-base md:text-lg"
                    >
                      Contact No.
                      <input
                        className=" py-2 md:py-3 px-2  placeholder:font-light placeholder:opacity-60  rounded-md  md:px-3 border border-solid  bg-white font-normal text-base"
                        type="number"
                        id="phone"
                        inputMode="numeric"
                        placeholder="Your digits are safe with us. We're too cool for cold calls anyway."
                        {...register('phone')}
                      />
                      {errors.phone && (
                        <span className="text-red-800 text-sm font-medium block mt-1">
                          {errors.phone?.message}
                        </span>
                      )}
                    </label>
                  </div>
                  {isPurposeField && (
                    <div>
                      <label htmlFor="purpose">
                        <span className="text-sm md:text-lg">Purpose</span>
                        <Listbox
                          value={purpose}
                          onChange={(elem) => {
                            setPurpose(elem)
                            setTouched(true)
                          }}
                        >
                          <div className="relative mt-1">
                            <Listbox.Button className=" rounded-md bg-white  px-2 md:px-3  py-2 md:py-3 flex items-center gap-2 justify-between border border-solid border-opacity-40   w-full text-left focus:outline-violet">
                              <span className="block truncate ">
                                <span>{purpose.title}</span>
                              </span>
                              <span className="  p-[2px]">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth="3"
                                  stroke="currentColor"
                                  className=" h-4 w-4 md:w-5 md:h-5"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                                  />
                                </svg>
                              </span>
                            </Listbox.Button>
                            <Transition
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm  z-40">
                                {purposes.map((elem) => (
                                  <Listbox.Option
                                    key={elem.id}
                                    className={({ active }) =>
                                      `relative cursor-default hover:bg-lightOrange select-none text-base ${
                                        active ? 'bg-lightOrange' : 'text-black'
                                      }`
                                    }
                                    value={elem}
                                  >
                                    {({ selected }) => (
                                      <span
                                        className={`block truncate  pl-6 pr-4 py-3 ${
                                          selected
                                            ? 'font-semibold text-white bg-green bg-opacity-90'
                                            : 'font-normal'
                                        }`}
                                      >
                                        {elem.title}
                                      </span>
                                    )}
                                  </Listbox.Option>
                                ))}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </Listbox>
                        {purpose.id === 'none' && isTouched && (
                          <span className="text-red-800 text-sm font-medium block mt-1">
                            Please select a purpose of contact.
                          </span>
                        )}
                      </label>
                    </div>
                  )}
                  <div>
                    <label
                      htmlFor="message"
                      className="grid gap-1 text-sm md:text-lg"
                    >
                      Message
                      <textarea
                        rows={7}
                        cols={5}
                        id="message"
                        className=" py-2 md:py-3 px-2  placeholder:font-light placeholder:opacity-60  rounded-md  md:px-3 border border-solid  bg-white font-normal text-base"
                        placeholder="Got something to say? We're all ears!"
                        {...register('message')}
                      />
                    </label>
                  </div>
                  <div className="  block md:grid place-content-start ">
                    <MasterBtn
                      type="submit"
                      text={isLoading ? 'Loading...' : 'Join Now'}
                      isDisabled={isLoading}
                      size="large"
                    />
                  </div>
                </form>
              </Slide>
            </div>
          </div>
        </section>
      </section>
    </>
  )
}
