import React from 'react'
import ContactForm from 'organisms/contact_form'

export default function Form() {
  return (
    <section>
      <section>
        <div
          style={{
            background:
              'linear-gradient(180deg, #FFFEFE 0, #CCDBE5 72.83%, #CCDBE5 100%)',
          }}
          className="blade-top-padding blade-bottom-padding"
        >
          <ContactForm
            isPurposeField={false}
            formTitle="Ready to do a <br  /> Good Deed?"
          />
        </div>
      </section>
    </section>
  )
}
