import React from 'react'
import mobileApp from 'assets/home/cards/mobile.webp'
import scanner from 'assets/home/cards/qr_code.webp'
import foodBag from 'assets/home/cards/food_bag.webp'
import meltingEarth from 'assets/home/how_to.svg'
import Reveal from 'molecules/reveal'

// contains two blade
export default function HowTo() {
  return (
    <>
      <section className=" orange-bg-mesh blade-top-padding lg:blade-bottom-padding-lg blade-top-padding-lg  bg-no-repeat ">
        <div className=" lg:flex-row flex flex-col gap-8 w-container-lg blade-top-padding-sm blade-bottom-padding-sm">
          <div className="basis-0 grow shrink   flex justify-center flex-col ">
            <h2 className="font-extrabold mt-2 mb-6  text-black w-11/12 ">
              How to do a{' '}
              <span className="text-orange">
                {' '}
                <br className="md:block hidden" /> GOOD DEED?
              </span>
            </h2>
            <h5 className="text-black max-w-xl  pr-3 md:w-11/12 font-semibold ">
              We are on a mission to reduce the burden on landfills by helping
              the HORECA (Hotel - Restaurants and Caterers) industry sell every
              last pack of freshly cooked meals, all while giving foodies good
              quality, fresh food and helping them do a good deed.
            </h5>
          </div>
          <div className="basis-32 grow sherink">
            <div className="sm:mt-10 lg:mt-0 mt-5  ">
              <img
                src={meltingEarth}
                className=" max-w-xl lg:max-w-none mx-auto  w-full h-full "
                alt="Melting Earth depicting environmental problems."
              />
            </div>
          </div>
        </div>
      </section>

      {/* another blade */}
      <section
        style={{ background: 'linear-gradient(180deg, #FFF5EC 0%, #FFF 100%)' }}
      >
        <section className="w-container blade-top-padding-lg blade-bottom-padding-lg ">
          <div className="blade-top-padding-sm blade-bottom-padding">
            <Reveal>
              <h3 className="text-center px-3 font-bold">How it works</h3>
            </Reveal>
            <Reveal delay={600}>
              <h1 className="lg:block hidden font-bold text-green text-center px-6  mt-2 md:mt-3 xl:mt-4 mb-4 md:mb-6 xl:mb-8">
                Unleashing a sustainable world
              </h1>
              <h3 className=" lg:hidden block font-bold text-green text-center px-6  mt-2 md:mt-3 xl:mt-4 mb-4 md:mb-6 xl:mb-8">
                Unleashing a sustainable world
              </h3>
            </Reveal>

            <div className=" mx-auto w-11/12 md:w-full 2xl:px-10 ">
              <div className="lg:flex-row flex-col flex gap-y-12 md:gap-8  justify-center blade-top-padding  ">
                <Reveal delay={600} className="mx-auto">
                  <ProcessCard
                    bgImage={mobileApp}
                    step={1}
                    desc="Download the Good Deed app and discover great offers from your favorite eateries."
                  />
                </Reveal>
                <Reveal delay={600} className="mx-auto">
                  <ProcessCard
                    bgImage={scanner}
                    step={2}
                    desc="Order fresh surplus food and complete the payment using your preferred payment method."
                  />
                </Reveal>

                <Reveal delay={600} className="mx-auto">
                  <ProcessCard
                    bgImage={foodBag}
                    step={3}
                    desc="Get your order delivered to your doorstep."
                  />
                </Reveal>
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  )
}

type ProcessProps = {
  desc: string
  step: number
  bgImage?: string // make the property non-optional in production
}

function ProcessCard({ desc, step, bgImage }: ProcessProps) {
  return (
    <article className="mx-3 flex-1  h-full max-w-sm 2xl:max-w-[420px] md:mx-auto orange-outline-border overflow-hidden">
      <div className="overflow-hidden">
        <img
          src={bgImage}
          alt={desc}
          className="h-full w-full object-contain object-center"
        />
      </div>
      <div className="flex flex-col items-start gap-4 mt-4 py-3  xl:pt-5 xl:py-9 pl-4 md:pl-7 md:pr-7   pb-7 xl:pr-10 ">
        <h6 className="px-5 pt-[2px] pb-[1px] bg-lightOrange rounded-full text-black font-semibold">
          Step {step}
        </h6>
        <h5 className="leading-normal md:block hidden">{desc}</h5>
        <span className="leading-normal md:hidden block w-11/12">{desc}</span>
      </div>
    </article>
  )
}

/*


 <section className="orange-bg-mesh blade-top-padding-sm blade-bottom-padding-lg  ">
        <div className=" blade-top-padding blade-bottom-padding-lg w-container  md:blade-top-margin-lg mb-48 lg:mb-44">
          <h1 className="lg:block hidden text-darkGreen font-extrabold">
            How to do a{' '}
            <span className="text-orange">
              {' '}
              <br className="md:block hidden" /> GOOD DEED?
            </span>
          </h1>
          <h2 className="lg:hidden block text-darkGreen font-extrabold">
            How to do a{' '}
            <span className="text-orange">
              {' '}
              <br className="md:block hidden" /> GOOD DEED?
            </span>
          </h2>
          <h6 className="text-black max-w-md xl:max-w-xl font-semibold mt-2 w-10/12 xl:w-11/12  ">
            We are on a mission to reduce the burden on landfills by helping the
            HORECA (Hotel - Restaurants and Caterers) industry sell every last
            pack of freshly cooked meals, all while giving foodies good quality,
            fresh food and helping them do a good deed.
          </h6>
        </div>
      </section>


*/
