import React from 'react'
import emailIcon from 'assets/contact/email.svg'

export default function ContactInfo() {
  return (
    <section className="blade-top-padding-lg blade-top-margin-lg blade-bottom-padding">
      <div className="blade-top-padding-lg blade-bottom-padding">
        <div
          style={{
            background: 'linear-gradient(180deg, #FFF 0%, #FFF6EE 100%)',
          }}
          className="blade-top-padding-sm max-w-screen-xl orange-outline-border md:mx-auto  mx-5 md:w-11/12 blade-bottom-padding-sm orange-outline-border"
        >
          <div className="mb-6 lg:mb-5">
            <img
              src={emailIcon}
              alt="Click to email to Good Deed team."
              className="mx-auto"
            />
          </div>
          <h3 className="text-center font-extrabold mb-3">
            Let’s discuss more!
          </h3>
          <div className=" gap-5 flex mt-5 mb-3  items-center flex-col md:flex-row justify-center">
            <EmailItem href="mailto:info@gooddeed.in" text="info@gooddeed.in" />
            <EmailItem
              href="mailto:founder@gooddeed.in"
              text="founder@gooddeed.in"
            />
          </div>
        </div>
      </div>
    </section>
  )
}

function EmailItem({ text, href }: { text: string; href: string }) {
  return (
    <a
      href={href}
      className="px-7  underline-offset-4 decoration-from-font rounded-full py-2 md:py-1  border-orange hover:bg-lightOrange transition-all duration-300 ease-in-out focus-visible:bg-lightOrange border-solid border-1"
    >
      <h6 className="font-semibold leading-loose">{text} </h6>
    </a>
  )
}
