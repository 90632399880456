import React, { useEffect, useRef } from 'react'
import obama from 'assets/about/obama.webp'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import SplitType from 'split-type'
import Reveal from 'molecules/reveal'

gsap.registerPlugin(ScrollTrigger)

export default function Banner() {
  const obamaRef = useRef<HTMLImageElement>(null)
  useEffect(() => {
    const textBig = new SplitType('.target', { types: 'words' })
    const textSmall = new SplitType('.target', { types: 'words' })

    const targetsBig = textBig.words
    const targetsSmall = textSmall.words

    gsap
      .timeline()
      .fromTo(
        targetsBig,
        { opacity: 0, translateY: 10 },
        {
          opacity: 1,
          duration: 0.5,
          translateY: 0,

          stagger: 0.1,
        },
      )
      .fromTo(
        targetsSmall,
        { opacity: 0, translateY: 10 },
        {
          opacity: 1,
          translateY: 0,
          duration: 0.5,
          stagger: 0.05,
        },
        '<',
      )
      .fromTo(
        '.obama-large',
        {
          opacity: 0,
        },
        { opacity: 1, duration: 0.5 },
        '0.3',
      )
      .fromTo(
        '.obama-small',
        {
          opacity: 0,
        },
        { opacity: 1, duration: 0.5, delay: 1.4 },
        '0.3',
      )
  }, [])
  return (
    <section>
      <div className="mt-14 md:mt-0">
        <div className="blade-top-padding-lg blade-bottom-padding-lg mb-4 ">
          <h2 className="target ont-bold text-center  px-5  sm:w-10/12 lg:w-full mx-auto blade-top-padding-lg blade-top-margin-lg leading-relaxed md:block hidden">
            It all started with{' '}
            <img
              src={obama}
              alt="Obama giving a speech "
              aria-hidden
              className="inline-block  h-12 xl:h-auto obama-large"
            />{' '}
            Obama <br className="lg:block hidden" /> and his rhetorical speech
            on food <br className="lg:block hidden" /> waste management!
          </h2>
          <h3 className=" target font-bold text-center  px-5  sm:w-10/12 lg:w-full mx-auto blade-top-padding-lg blade-top-margin-lg leading-relaxed md:hidden block">
            It all started with{' '}
            <img
              src={obama}
              alt="Obama giving a speech "
              aria-hidden
              className="inline-block h-7 mb-1  obama-small"
            />{' '}
            Obama <br className="lg:block hidden" /> and his rhetorical speech
            on food <br className="lg:block hidden" /> waste management!
          </h3>
        </div>
      </div>
      <div className="w-container blade-bottom-padding-lg blade-bottom-margin blade-top-padding-sm blade-top-margin-sm">
        <Reveal delay={700}>
          <div className="max-w-screen-md 2xl:max-w-screen-lg mx-auto text-center blade-bottom-padding">
            <h4 className="leading-relaxed md:block hidden">
              The Good Deed team discovered that food waste causes about{' '}
              <span className="text-orange font-semibold">
                3.3 billion tonnes of greenhouse gas emissions every year{' '}
              </span>
              and if it were a country, it would be the world’s third-largest
              producer of carbon dioxide, after the US and China.
            </h4>
            <span className="leading-relaxed md:hidden block text-sm px-5">
              The Good Deed team discovered that food waste causes about{' '}
              <span className="text-orange font-semibold">
                3.3 billion tonnes of greenhouse gas emissions every year{' '}
              </span>
              and if it were a country, it would be the world’s third-largest
              producer of carbon dioxide, after the US and China.
            </span>
          </div>
        </Reveal>
      </div>
    </section>
  )
}
