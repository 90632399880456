import React, { useLayoutEffect } from 'react'
import Banner from './01_banner'
import CardGrid from './02_card_grid'
import EcoWarriors from './03_eco_warriors'
import HowItWorks from './04_how_it_works'
import Form from './05_form'

export default function Business() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <Banner />
      <CardGrid />
      <EcoWarriors />
      <HowItWorks />
      <Form />
    </>
  )
}
