import React, { useState, useRef, useLayoutEffect } from 'react'
import gsap from 'gsap'

const accordionData: AccordionItem[] = [
  {
    title: 'What is Good Deed?',
    content:
      'Good Deed helps rescue surplus fresh food from eateries and offers it at affordable prices. With Good Deed you can say goodbye to food waste and hello to delicious meals that save the world one bite at a time!',
  },

  {
    title: 'What makes Good Deed a win-win-win for everyone?',
    content:
      'Good Deed is the ultimate triple treat! We save you money with jaw-dropping discounts on restaurant meals. We save restaurants from the sunk costs of wasted food. And we save the planet by reducing food waste and its harmful impact. ',
  },
  {
    title: 'What type of food gets sold on Good Deed?',
    content:
      "From sizzling North Indian delights to tantalizing continental cuisine, zesty Italian dishes, and soulful South Indian treats. You'll find a world of flavors waiting to rescue your taste buds!",
  },
  {
    title: 'What quality control measures are taken by Good Deed?',
    content:
      "At Good Deed, we don't compromise on quality. We have strict standards in place, ensuring only the finest surplus food makes it to your plate. Any dish that does not pass our quality check, does not make it on the menu!",
  },
  {
    title:
      "What is the benefit for me as a restaurant, café, hotel, etc. to be part of Good Deed's platform?",
    content:
      'By joining Good Deed, you can recoup money on surplus items that would have otherwise met a tragic end. Plus, 69% of customers who discover your store through Good Deed are likely to become loyal, full-paying patrons. Get ready to attract hungry heroes who appreciate your commitment to saving the world, one meal at a time!',
  },
  {
    title: 'What is the Good Deed Community?',
    content:
      'The Good Deed Community is a gathering of eco-conscious individuals united by a common mission: to fight food waste and save our planet! Together, we inspire, empower, and make a tangible difference. Join the community and become a superhero in the battle against food waste!',
  },
  {
    title: 'How can I become a part of Good Deed?',
    content:
      'Simply download the Good Deed app and register as either a user or a restaurant. Order from a variety of eateries selling fresh surplus food or upload dishes while saving the planet.',
  },
  {
    title: 'Is food waste really such a huge problem?',
    content:
      "Holy moly, food waste is a colossal crisis! We're talking about one-third of the world's food production going to waste, causing havoc on our planet. It worsens climate change and contributes to 120 million tonnes of carbon emissions.",
  },
  {
    title:
      'How is Good Deed different from other online food delivery platforms?',
    content:
      "Good Deed doesn't just deliver food, it offers discounted fresh meals that would have been discarded, giving you the power to enjoy guilt-free feasting while saving the environment from food waste.",
  },
]

function FAQ() {
  const [openIndex, setOpenIndex] = useState<number | null>(null)

  const handleOpen = (index: number) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index))
  }

  return (
    <section className="w-container ">
      <div className="text-center px-3 blade-top-padding-lg blade-bottom-padding-lg">
        <h3 className="text-paleBlue font-extrabold">Curiosity Corner</h3>
      </div>
      <div className="accordion  grid grid-cols-1 lg:grid-cols-2 gap-y-5 md:gap-y-7 lg:gap-y-10 gap-x-20">
        <div className="flex flex-col gap-5 md:gap-7 lg:gap-10">
          {accordionData
            .slice(0, accordionData.length / 2 + 1)
            .map((accordion: AccordionItem, index: number) => {
              const key = index
              return (
                <Accordion
                  key={key}
                  title={accordion.title}
                  content={accordion.content}
                  isOpen={openIndex === index}
                  index={key}
                  handleOpen={handleOpen}
                />
              )
            })}
        </div>
        <div className=" flex flex-col gap-5 md:gap-7 lg:gap-10">
          {accordionData
            .slice(accordionData.length / 2 + 1)
            .map((accordion: AccordionItem, index: number) => {
              const key = index + Math.ceil(accordionData.length / 2)
              return (
                <Accordion
                  key={key}
                  title={accordion.title}
                  content={accordion.content}
                  isOpen={openIndex === key}
                  index={key}
                  handleOpen={handleOpen}
                />
              )
            })}
        </div>
      </div>
    </section>
  )
}

interface AccordionProps {
  title: string
  content: string
  isOpen: boolean
  index: number
  handleOpen: (index: number) => void
}

interface AccordionItem {
  title: string
  content: string
}

function Accordion({
  title,
  content,
  handleOpen,
  index,
  isOpen,
}: AccordionProps) {
  const accordionRef = useRef<HTMLDivElement>(null)
  const toggleAccordion = () => {
    handleOpen(index)
  }

  useLayoutEffect(() => {
    const accordionContent = accordionRef.current as HTMLElement
    if (isOpen) {
      gsap.to(accordionContent, { height: 'auto', duration: 0.3, padding: 0 })
    } else {
      gsap.to(accordionContent, { height: 0, duration: 0.3, padding: 0 })
    }
  }, [isOpen])

  return (
    <div
      className={` ${
        isOpen ? 'border-orange' : 'border-white'
      }  border-2 border-solid  self-start accordion-item  border-opacity-30 rounded-md overflow-hidden `}
    >
      <button
        type="button"
        className={`accordion-header flex  transition-colors  duration-300 ease-in-out items-center gap-3 py-5 rounded-md bg-white pl-2 pr-3   md:px-5 w-full ${
          isOpen ? 'bg-[#F3F3F3]' : ''
        }`}
        onClick={toggleAccordion}
      >
        <h6 className="flex-1 font-semibold px-2 text-left"> {title}</h6>
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={3}
            stroke="currentColor"
            className={` w-5 h-5 ${
              isOpen ? 'rotate-180' : ''
            } duration-300 ease-in-out transition-all`}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 8.25l-7.5 7.5-7.5-7.5"
            />
          </svg>
        </div>
      </button>
      <div
        style={{ background: 'linear-gradient(180deg, #FFF 0%, #FFF6EE 100%' }}
        className={`accordion-content overflow-hidden  ${isOpen ? 'open' : ''}`}
        ref={accordionRef}
      >
        <span className=" block sm:text-base  leading-relaxed  text-sm md:text-lg  px-4 md:px-7 md:w-10/12  py-5 ">
          {' '}
          {content}
        </span>
      </div>
    </div>
  )
}

export default FAQ
