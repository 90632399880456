import React, { useEffect, useRef } from 'react'
import overlay from 'assets/about/lifeline/1.webp'
import bgGreen from 'assets/about/lifeline/2.webp'
import bgWhite from 'assets/about/lifeline/3.webp'

import bulletPoint from 'assets/about/star.svg'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

export default function Lifeline() {
  const imgWrapper = useRef<HTMLDivElement>(null)
  const wrapper = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const selector = gsap.utils.selector(imgWrapper.current)
    const animations = gsap.fromTo(
      selector('img'),
      {
        opacity: 0,
        rotateZ: 10,
        translateX: 10,
      },
      {
        opacity: 1,
        rotateZ: 0,
        translateX: 0,
        duration: 1,
        stagger: 0.5,
        scrollTrigger: {
          trigger: wrapper.current,
          start: 'top top',
        },
      },
    )
  }, [])
  return (
    <section
      style={{ background: 'linear-gradient(180deg, #FFF 0%, #CCDBE5 100%)' }}
    >
      <section className="w-container-lg 2xl:w-container blade-top-padding blade-bottom-padding-lg blade-bottom-padding">
        <div className=" flex  lg:flex-row flex-col-reverse   gap-y-10 md:gap-y-20 xl:gap-7  2xl:gap-10 blade-bottom-padding-sm    ">
          <div className=" blade-top-padding blade-bottom-padding flex basis-24 grow shrink flex-col justify-center">
            <h3 className="font-bold max-w-md xl:max-w-4xl  xl:pr-10 leading-normal  md:block hidden">
              Good Deed is more than just a food-ordering platform!
            </h3>
            <h2 className="font-extrabold text-[#1B3F5B]  mt-4 lg:mt-6 lxl:mt-8 md:block hidden ">
              It&apos;s a lifeline for our planet.
            </h2>
            <h3 className="font-bold max-w-md xl:max-w-4xl leading-normal  md:hidden block">
              Good Deed is more than just a food-ordering platform!
            </h3>
            <h4 className="font-extrabold text-[#1B3F5B]  mt-4 lg:mt-6 lxl:mt-8 md:hidden block ">
              It&apos;s a lifeline for our planet.
            </h4>
            <ul className=" max-w-xl xl:max-w-3xl flex flex-col gap-5 lg:gap-8 mt-6 lg:mt-8 xl:mt-12 pr-5 lg:w-9/12">
              <ListItem
                text="Good Deed enables environment-conscious consumers to order fresh and
        mouth-watering surplus food from their favorite eateries, helping
        restaurants save sunk costs."
              />
              <ListItem
                text="With its mission to rescue the Earth from the dreads of food
                waste and the pollution it causes, it has become an app every
                eco-warrior would vouch for!"
              />
            </ul>
          </div>
          <div className=" flex-1" ref={wrapper}>
            <div
              ref={imgWrapper}
              className="w-full h-full  grid place-content-center relative "
            >
              <img
                src={bgWhite}
                alt="white background"
                aria-hidden
                className="absolute top-0 left-0 bottom-0 right-0 w-full h-full object-contain "
              />
              <img
                src={bgGreen}
                alt="green background"
                aria-hidden
                className="absolute top-0 left-0 bottom-0 right-0 w-full h-full object-contain "
              />
              <img
                src={overlay}
                alt="man cooking some food"
                className="absolute top-0 left-0 bottom-0 right-0 w-full h-full object-contain "
              />
            </div>
          </div>
        </div>
      </section>
    </section>
  )
}

function ListItem({ text }: { text: string }) {
  return (
    <li className="flex flex-nowrap items-start gap-3">
      <img
        src={bulletPoint}
        alt="Bullet Point icon"
        className="basis-4 grow-0 shrink mt-[9px] h-5"
      />
      <h6 className="flex-1">{text}</h6>
    </li>
  )
}
